'use strict';

// This file is about form fields manipulations.
const helper = require('../../helper');
const creditCardHelper = require('./creditCardHelper');

const PREFIX = 'braintree-';

/**
 * Returns an object contains an object and an array of credit card fields to Display
 * @returns {Object} Object
 */
function getCCFieldsToDisplay() {
    const hostedFieldsConfig = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();

    const ccToDisplayFieldsAsObject = hostedFieldsConfig.fields
        .filter((field) => field !== 'cvv')
        .reduce((accumulator, field) => {
            accumulator[`${field}ToDisplay`] = document.getElementById(`${PREFIX + helper.toKebabCase(field)}-to-display`);

            return accumulator;
        }, {});

    return {
        asObject: ccToDisplayFieldsAsObject,
        asArray: Object.values(ccToDisplayFieldsAsObject)
    };
}

/**
 * Returns an object contains an object and an array of credit card cvv field to Display
 * @returns {Object} Object
 */
const getCcCvvToDisplayField = () => {
    const asObject = {
        cvvToDisplay: document.getElementById(`${PREFIX}cvv-to-display`)
    };

    return {
        asObject: asObject,
        asArray: Object.values(asObject)
    };
};

/**
 * Returns an object contains an object and an array of cvv credit card field
 * @returns {Object} Object
 */
const getCcCvvField = () => {
    const asObject = {
        braintreeCvv: document.getElementById(`${PREFIX}cvv`)
    };

    return {
        asObject: asObject,
        asArray: Object.values(asObject)
    };
};

/**
 * Returns an object contains an object and an array of credit card fields
 * @returns {Object} Object
 */
function getCCFields() {
    const hostedFieldsConfig = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();

    const ccFieldsAsObject = hostedFieldsConfig.fields
        .filter((field) => field !== 'cvv')
        .reduce((accumulator, field) => {
            accumulator[field] = document.getElementById(PREFIX + helper.toKebabCase(field));

            return accumulator;
        }, {});

    return {
        asObject: ccFieldsAsObject,
        asArray: Object.values(ccFieldsAsObject)
    };
}

/**
 * Returns braintreeCreditCardNonce container
 * @returns {Object} Input element
 */
function getCreditCardNonceFieldContainer() {
    return document.getElementById('braintreeCreditCardNonce');
}

/**
 * Returns braintreeCreditCardList container
 * @returns {Object} Select element
 */
function getCreditCardListContainer() {
    return document.getElementById('braintreeCreditCardList');
}

/**
 * Sets Credit Card field to display
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields to display
 */
function setCreditCardFieldsToDisplay(selectedCard, cacheCardFields) {
    const cartNumber = selectedCard.dataset.number;

    cacheCardFields.numberToDisplay.innerHTML = cartNumber;
    cacheCardFields.expirationDateToDisplay.innerHTML = selectedCard.dataset.expiration;
    cacheCardFields.cardholderNameToDisplay.innerHTML = selectedCard.dataset.owner;

    if (cacheCardFields.cvvToDisplay) {
        cacheCardFields.cvvToDisplay.innerHTML = '***';
    }

    document.getElementById('braintreeCardType').value = selectedCard.dataset.type;
    document.getElementById('braintreeCardMaskNumber').value = cartNumber;
}

/**
 * Sets Credit Card fileds on the Billing Page
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields
 */
function setCardFields(selectedCard, cacheCardFields) {
    cacheCardFields.numberToDisplay.innerHTML = selectedCard['data-number'].value;
    cacheCardFields.expirationDateToDisplay.innerHTML = selectedCard['data-expiration'].value;
    cacheCardFields.cardholderNameToDisplay.innerHTML = selectedCard['data-owner'].value;

    if (cacheCardFields.cvvToDisplay) {
        cacheCardFields.cvvToDisplay.innerHTML = '***';
    }

    document.getElementById('braintreeCardType').value = selectedCard['data-type'].value;
    document.getElementById('braintreeCardMaskNumber').value = selectedCard['data-number'].value;
}

/**
 * Shows Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of credit card fileds
 */
function showCardElements(cardFields) {
    cardFields.forEach(function(el) {
        el.style.display = '';
    });
}

/**
 * Hides Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of Credit Card fileds
 */
function hideCardElements(cardFields) {
    cardFields.forEach(function(el) {
        el.style.display = 'none';
    });
}

/**
 * Returns Credit Card hosted fileds styles
 * @returns {Object} Object of styles
 */
function getHostedFieldsStyles() {
    return {
        input: {
            'font-size': '16px',
            "font-weight": "300",
            "font-family": "-apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue'\", Arial, \"Noto Sans\", sans-serif",
            color: '#000000',
            'padding-left': '1rem',
            'padding-right': '1rem'
        },
        ':focus': {
            color: '#000000'
        },
        '.valid': {
            color: '#000000'
        },
        '.invalid': {
            color: '#000000'
        },
        '::placeholder': {
            color: '#000000'
        }
    };
}

module.exports = {
    getCCFieldsToDisplay,
    getCCFields,
    getCreditCardNonceFieldContainer,
    getCreditCardListContainer,
    showCardElements,
    hideCardElements,
    setCreditCardFieldsToDisplay,
    setCardFields,
    getHostedFieldsStyles,
    getCcCvvToDisplayField,
    getCcCvvField
};
