'use strict';

const braintreeApplePayModelBuilder = require('base_braintree/braintree/applepay/braintreesdk/braintreeApplePayModelBuilder');
const applePayPaymentProcessing = require('./components/applePayPaymentProcessing');
const applePayErrorMessages = require('base_braintree/braintree/applepay/constants/errorMessages');

const AlertHandlerModel = require('base_braintree/braintree/applepay/alerthandler/applePayAlertHandlerModel');

let braintreeApplePayModelResponse;
let applePayMessages;

/**
 * Inits Apple Pay payment processing on Cart/Checkout Page
 */
function initApplePay() {
    const alertHandler = new AlertHandlerModel(applePayMessages);

    if (!braintreeApplePayModelResponse || braintreeApplePayModelResponse.error) {
        const error = braintreeApplePayModelResponse.error || applePayErrorMessages.NO_APPLE_PAY_BRAINTREE_CONFIGURATIONS;

        alertHandler.handleApplePayError(error);
    }
    applePayPaymentProcessing.init(braintreeApplePayModelResponse.btApplePayModel,
        alertHandler);
}

/**
 * Inits Apple Pay on the Cart/Billing-Checkout Pages
 */
function init() {
    braintreeApplePayModelResponse = braintreeApplePayModelBuilder.init();

    if (!braintreeApplePayModelResponse.error) {
        applePayMessages = braintreeApplePayModelResponse.btApplePayConfigs.messages;
    }
}

module.exports = {
    init,
    initApplePay
};
