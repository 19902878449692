'use strict';

/* global braintreeUtils braintree $ */

const googlePaySDKHelper = require('../helpers/googlePaySDKHelper');

let btClientInstancePromise;

/**
 * Creates googlePayment instance
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Promise} A promise resolve with the GooglePayment instance
 */
function createGooglePayment(googleMerchantId) {
    return btClientInstancePromise
        .then(function(btClientInstance) {
            const googlePayOptions = googlePaySDKHelper.createGooglePayOptions(btClientInstance,
                googleMerchantId);

            return braintree.googlePayment.create(googlePayOptions);
        });
}

/**
 * Creates a configuration object for use in the loadPaymentData method.
 * @param {googlePaymentInstance} googlePaymentInstance Google Pay instance
 * @param {Object} options Object with payment options
 * @param {number} amount Order total amount
 * @returns {Object} Configuration object
 */
function createPaymentDataRequest(googlePaymentInstance, options, amount) {
    const requestObject = {
        transactionInfo: {
            currencyCode: options.currency,
            totalPriceStatus: 'FINAL',
            totalPrice: String(amount)
        },
        merchantInfo: {
            merchantName: options.displayName
        },
        shippingAddressRequired: options.isShippingAddressRequired,
        shippingAddressParameters: {
            allowedCountryCodes: options.countries,
            phoneNumberRequired: true
        },
        emailRequired: true,
        callbackIntents: ['PAYMENT_AUTHORIZATION']
    };

    if (options.isShippingOptionRequired) {
        requestObject.shippingOptionRequired = options.isShippingOptionRequired;
        requestObject.shippingOptionParameters = {
            defaultSelectedOptionId: googlePaySDKHelper.getSelectedShippingId(),
            shippingOptions: options.shippingOptions
        };
        requestObject.callbackIntents.push('SHIPPING_ADDRESS', 'SHIPPING_OPTION');
    }

    if (options.merchantId) {
        requestObject.merchantInfo.merchantId = options.merchantId;
    }

    return googlePaymentInstance.createPaymentDataRequest(requestObject);
}

/**
 * Parse the response from the tokenization.
 * @param {Object} paymentData The response back from the Google Pay tokenization.
 * @param {googlePaymentInstance} googlePaymentInstance Google Pay instance
 * @returns {Promise} Promise that resolve with a tokenizePayload
 */
function parseResponse(paymentData, googlePaymentInstance) {
    return googlePaymentInstance.parseResponse(paymentData);
}

/**
 * Parse the billingAddress from GooglePay to 3DS form
 * @param {Object} oldBillingData billingAddress from GooglePay.
 * @returns {Object} parsed to 3DS form billing data
 */
function parseGooglePayBillingDataTo3DSForm(oldBillingData) {
    return {
        givenName: oldBillingData.name,
        surname: oldBillingData.lastName,
        phoneNumber: oldBillingData.phoneNumber,
        streetAddress: oldBillingData.address1,
        extendedAddress: oldBillingData.address2,
        locality: oldBillingData.locality,
        region: oldBillingData.administrativeArea,
        postalCode: oldBillingData.postalCode,
        countryCodeAlpha2: oldBillingData.countryCode
    };
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {Object} verifyCardObject Object with Credit Card info for verification
 * @param {string} threeDSecureFlow 3DSecure flow value
 * @returns {Object} 3DS instance
 */
function apply3dSecureValidation(verifyCardObject, threeDSecureFlow) {
    const braintreeCreditCardSDK = require('../../creditcard/braintreesdk/braintreeCreditCardSDK');

    return btClientInstancePromise
        .then(function(btClientInstance) {
            verifyCardObject.billingAddress = parseGooglePayBillingDataTo3DSForm(verifyCardObject.billingAddress);

            return braintreeCreditCardSDK
                .create3DSecureRequest(btClientInstance,
                    verifyCardObject,
                    threeDSecureFlow
                );
        });
}

/**
 * Inits braintreeGooglePaySDK with client instance promise
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    createGooglePayment,
    createPaymentDataRequest,
    parseResponse,
    apply3dSecureValidation,
    init
};
