'use strict';

var payPalCartPage = require('./components/payPalCartPage');

var btClientInstancePromise;

/**
 * Init PayPal functionality on Cart
 */
function initCart() {
    payPalCartPage.init(btClientInstancePromise);
}
function init(clientInstancePromise) {
    btClientInstancePromise = clientInstancePromise;
}

module.exports = {
    init,
    initCart
};
