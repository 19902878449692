'use strict';

const helper = require('../helper');
const AlertHandler = require('../alertHandler');
const fastlaneHelper = require('./fastlaneHelper');
const braintreeGeneral = require('../braintreeGeneral');

const ROLE_USER = 'user';
const ROLE_GUEST = 'guest';

const CSS_CLASS_D_NONE = 'd-none';
const FASTLANE_USER_ROLE = 'fastlaneUserRole';
const FASTLANE_EMAIL = 'fastlaneEmail';
const FASTLANE_CARD = 'fastlaneCard';
const CSS_CLASS_SHIP_TO_PHONE = '.ship-to-phone';

class Fastlane {
    #isPhonePrefilled = false;

    constructor() {
        if (this.isSandbox()) {
            localStorage.setItem('axoEnv', 'SANDBOX');
            localStorage.setItem('fastlaneEnv', 'sandbox');
        }

        this.isUserFlowRunOnce = true;
        this.isGuestFlowRunOnce = true;
        this.isCheckoutFromBeginning = false;

        this.isPaymentUI = window.braintreePreferences.isFastlanePaymentUiEnabled;
        this.isCardholderNameEnabled = window.braintreePreferences.fastlaneCardholderName;

        this.loginWatermarkEl = document.getElementById('login-watermark');
        this.cardWatermarkEl = document.getElementById('card-watermark');
        this.walletContainerEl = document.getElementById('fastlane-wallet-container');
        this.hostedFieldsEl = document.getElementById('fastlane-fields-container');
        this.lookupCustomerButtonEl = document.getElementById('submit-fastlane-customer');
        this.cardContainerEl = document.getElementById('fastlane-card-container');
        this.cardWatermarkContainerEl = document.querySelector('.card-watermark-container');
        this.addressContainerAllEls = document.querySelectorAll('.fastlane-address');
        this.firstAddressContainer = fastlaneHelper.getFirstElement(this.addressContainerAllEls);
        this.secondAddressContainer = fastlaneHelper.getSecondElement(this.addressContainerAllEls);

        this.checkoutMainEl = document.getElementById('checkout-main');
        this.cardListEl = document.getElementById('braintreeCreditCardList');
        this.multishipCheckbox = document.getElementById('multiShipCheck');
        this.submitShippingButtonEl = document.querySelector('button.submit-shipping');
        this.submitPaymentButtonEl = document.querySelector('button.submit-payment');
        this.creditCardTab = document.querySelector('.creditcard-tab');
        this.billingAddressSelectorBlockEl = document.querySelector('.address-selector-block');
        this.allShippingAddressForms = document.querySelectorAll('form[name=dwfrm_shipping]');
        this.firstShippingForm = fastlaneHelper.getFirstElement(this.allShippingAddressForms);
        this.secondShippingForm = fastlaneHelper.getSecondElement(this.allShippingAddressForms);
        this.allShipmentSelectorBlockEls = document.querySelectorAll('.shipment-selector-block');
        this.secondShipmentSelectorBlockEl = fastlaneHelper.getSecondElement(this.allShipmentSelectorBlockEls);
        this.allBtnEnterMultiship = document.querySelectorAll('.btn-enter-multi-ship');
        this.secondBtnEnterMultiship = fastlaneHelper.getSecondElement(this.allBtnEnterMultiship);
        this.customErrorMessages = helper.tryParseJSON(document.getElementById('customCreditCardErrorContainer').getAttribute('data-errors'));

        const { styles, sessionId } = helper.tryParseJSON(this.lookupCustomerButtonEl.dataset.fastlaneConfig);

        this.styles = styles;
        this.sessionId = sessionId;
        this.addressOptions = { allowedLocations: this.getAllowedCountries() };

        this.alertHandler = new AlertHandler();
    }

    async init() {
        try {
            await this.initCheckout();
            await this.initWatermarks();

            this.addEvents();
            this.setDeviceData();
            this.renderWatermarkWithTooltip(this.loginWatermarkEl);

            if (this.isNotCustomerStage()) {
                await this.determineFlow();
            }
        } catch (error) {
            this.alertHandler.showError(error.message);
        }
    }

    /**
     * Check that instanse type to be a Sandbox
     * @returns {boolean} True if it is not a production, false otherwise.
     */
    isSandbox() {
        return !window.braintreePreferences.isProduction;
    }

    /**
     * Checks if the current checkout stage is not 'customer'
     * @returns {boolean} Returns `true` if the current checkout stage is not 'customer', otherwise `false`.
     */
    isNotCustomerStage() {
        return this.checkoutMainEl.dataset.checkoutStage !== 'customer';
    }

    /**
     * Initializes checkout
     */
    async initCheckout() {
        const authorization = this.lookupCustomerButtonEl.dataset.btClientToken;

        const clientInstance = await braintreeGeneral.createClientInstance(authorization);

        this.dataCollector = await braintree.dataCollector.create({
            client: clientInstance,
            riskCorrelationId: this.sessionId
        });

        this.fastlane = await braintree.fastlane.create({
            authorization: authorization,
            client: clientInstance,
            deviceData: this.dataCollector.deviceData,
            styles: this.styles,
            shippingAddressOptions: this.addressOptions
        });

        this.identity = this.fastlane.identity;
        this.profile = this.fastlane.profile;
    }

    /**
     * Initialize watermarks
     */
    async initWatermarks() {
        this.watermark = await this.fastlane.FastlaneWatermarkComponent({ includeAdditionalInfo: false });
        this.watermarkWithTooltip = await this.fastlane.FastlaneWatermarkComponent({ includeAdditionalInfo: true });
    }

    /**
     * Render watermark with additional information (tooltip)
     * @param {HTMLElement} watermarkEl - watermark element
     */
    renderWatermarkWithTooltip(watermarkEl) {
        if (watermarkEl) {
            this.watermarkWithTooltip.render('#' + watermarkEl.id);
        }
    }

    /**
     * Render watermark without additional information
     * @param {HTMLElement} watermarkEl - watermark element
     */
    renderWatermark(watermarkEl) {
        if (watermarkEl) {
            this.watermark.render('#' + watermarkEl.id);
        }
    }

    /**
     * Render card component
     */
    async renderCardComponent() {
        let cardComponent;

        const fieldsParams = {
            fields: this.createFieldsParams()
        };

        if (!this.isPaymentUI) {
            cardComponent = await this.fastlane.FastlaneCardComponent(fieldsParams);
        } else {
            cardComponent = await this.fastlane.FastlanePaymentComponent(fieldsParams);

            const shippingAddress = fastlaneHelper.formatShippingAddress();

            if (shippingAddress) {
                await cardComponent.setShippingAddress(shippingAddress);
            }
        }

        this.cardComponent = cardComponent.render('#' + this.hostedFieldsEl.id);
    }

    /**
     * Render payment component
     */
    async renderPaymentComponent() {
        const paymentComponent = await this.fastlane.FastlanePaymentComponent();

        this.paymentComponent = paymentComponent.render('#' + this.walletContainerEl.id);
    }

    /**
     * Add event listeners
     */
    addEvents() {
        this.cardListEl.addEventListener('change', this.cardListChange.bind(this));
        this.submitPaymentButtonEl.addEventListener('click', this.handlePlaceOrderStep.bind(this));
        this.lookupCustomerButtonEl.addEventListener('click', this.handleLookupStep.bind(this));

        $(document).on('ajaxSuccess', this.handleAjaxSuccess.bind(this));
    }

    /**
     * Place order step
     * @param {Object} event - Event object
     * @returns {void}
     */
    async handlePlaceOrderStep(event) {
        if (!event.isTrusted) {
            return;
        }

        const isBillingFormValid = helper.validateForm(document.getElementById('dwfrm_billing'));

        fastlaneHelper.applyPreventEventMethods(event);
        helper.removeActiveSessionPayment();

        if ((fastlaneHelper.isFastlaneRoleGuest() || sessionStorage.getItem(FASTLANE_CARD) === 'false') && fastlaneHelper.isNewCardFlow()) {
            if (!isBillingFormValid) {
                this.alertHandler.showError(this.customErrorMessages.VALIDATION_INVALID);

                return;
            }

            await this.submitGuestPayment();
        } else if (this.isPaymentUI && fastlaneHelper.isFastlaneRoleUser() && sessionStorage.getItem(FASTLANE_CARD) === 'true') {
            const profileName = this.profileData?.name;
            const phoneNumber = this.profileData?.shippingAddress?.phoneNumber ?? '';

            const paymentData = await this.paymentComponent.getPaymentToken();

            if (paymentData) {
                this.billingAddress = fastlaneHelper.prepareAddressData(paymentData.paymentSource?.card?.billingAddress, profileName, phoneNumber);

                fastlaneHelper.handleBillingAddressUpdate(this.billingAddress);
                fastlaneHelper.setCardData(paymentData.paymentSource?.card, paymentData.id);

                if (!helper.validateForm(document.getElementById('dwfrm_billing'))) {
                    return;
                }
            }
        } else if (!isBillingFormValid) {
            return;
        }

        event.target.click();
    }

    /**
     * Look up step
     * @param {Object} event Event
     * @returns {void}
     */
    async handleLookupStep(event) {
        if (!event.isTrusted) {
            return;
        }

        if (!this.checkEmailField()) {
            return;
        }

        fastlaneHelper.applyPreventEventMethods(event);

        this.isCheckoutFromBeginning = true;
        this.lookupCustomerButtonEl.disabled = true;

        await this.determineFlow();
    }

    /**
     * Handle Ajax Success event
     * @param {Object} _event - The event object
     * @param {Object} _jqXHR - XMLHttpRequest object
     * @param {Object} _ajaxOptions - Ajax options object
     * @param {Object} response - Response
     */
    async handleAjaxSuccess(_event, _jqXHR, _ajaxOptions, response) {
        const isSubmitCustomerEndpoint = response.action === 'CheckoutServices-SubmitCustomer';
        const isSubmitShippingEndpoint = response.action === 'CheckoutShippingServices-SubmitShipping';
        const isOneClickPossible = !response.error && fastlaneHelper.isFastlaneRoleUser() && !fastlaneHelper.nonFastlaneNonceExist();
        const checkoutStage = this.checkoutMainEl?.dataset?.checkoutStage;
        const isShippingStage = checkoutStage === 'shipping';
        const isPaymentStage = checkoutStage === 'payment';
        const isFastlaneShippingData = this.profileData?.shippingAddress;
        const isFastlaneCardData = this.profileData?.card?.paymentSource?.card;

        if (isSubmitCustomerEndpoint) {
            this.lookupCustomerButtonEl.disabled = false;
        }

        if (isSubmitShippingEndpoint && isPaymentStage && this.isPaymentUI && fastlaneHelper.isFastlaneRoleGuest()) {
            await this.cardComponent.setShippingAddress(fastlaneHelper.formatShippingAddress());
        }

        if (isSubmitCustomerEndpoint && isOneClickPossible && isShippingStage && isFastlaneShippingData) {
            this.submitShippingButtonEl.click();
        }

        if (isSubmitShippingEndpoint && isOneClickPossible && isPaymentStage && isFastlaneCardData) {
            this.submitPaymentButtonEl.click();
        }
    }

    /**
     * Determines which flow should be handled on checkout (Fastlane guest/user flow)
     */
    async determineFlow() {
        const customerEmail = this.getCustomerEmail();

        if (customerEmail && customerEmail !== 'null') {
            if (this.isCheckoutFromBeginning) {
                this.handleCustomerLogin(customerEmail);

                if (!this.creditCardTab.classList.contains('active')) {
                    this.creditCardTab.click();
                }
            } else if (fastlaneHelper.nonFastlaneNonceExist()) {
                this.handleGuestFlow();

                return;
            }

            const {
                customerContextId
            } = await this.lookupCustomerByEmail(customerEmail);

            if (customerContextId) {
                const {
                    authenticationState,
                    profileData
                } = await this.triggerAuthenticationFlow(customerContextId);

                this.triggerContinueAsGuestClick();

                if (authenticationState === 'succeeded') {
                    this.profileData = profileData;
                    this.handleUserFlow();
                } else {
                    this.handleGuestFlow();
                }
            } else {
                this.triggerContinueAsGuestClick();
                this.handleGuestFlow();
            }
        }
    }

    /**
     * Look up for customer by customer's email
     * @param {string} email email value from input
     * @returns {Promise} Promise
     */
    lookupCustomerByEmail(email) {
        return this.identity.lookupCustomerByEmail(email.trim());
    }

    /**
     * Triggers authentication flow
     * @param {string} customerId customer id value
     * @returns {Promise} Promise
     */
    triggerAuthenticationFlow(customerId) {
        return this.identity.triggerAuthenticationFlow(customerId);
    }

    /**
     * Handle guest flow
     */
    async handleGuestFlow() {
        sessionStorage.setItem(FASTLANE_USER_ROLE, ROLE_GUEST);

        fastlaneHelper.showShippingAddressFormBlock();
        fastlaneHelper.hideFastlaneAddressBlock(this.addressContainerAllEls);
        fastlaneHelper.hideAddressWatermark();
        fastlaneHelper.clearElementContent(this.walletContainerEl);

        fastlaneHelper.addClass(this.cardContainerEl, CSS_CLASS_D_NONE);
        fastlaneHelper.addClass(this.walletContainerEl, CSS_CLASS_D_NONE);
        fastlaneHelper.addClass(this.cardWatermarkEl, CSS_CLASS_D_NONE);
        fastlaneHelper.addClass(this.cardWatermarkContainerEl, CSS_CLASS_D_NONE);

        if (this.multishipCheckbox?.checked) {
            this.showFormForMultishipFlow();
        }

        if (fastlaneHelper.isFastlaneRoleGuest()) {
            !this.isPaymentUI ? fastlaneHelper.removeClass(this.billingAddressSelectorBlockEl, CSS_CLASS_D_NONE)
                : fastlaneHelper.addClass(this.billingAddressSelectorBlockEl, CSS_CLASS_D_NONE);
        }

        if (fastlaneHelper.isSessionCardFlow()) {
            fastlaneHelper.showSessionCardBlock();
            fastlaneHelper.setNonce();
        } else {
            fastlaneHelper.removeClass(this.hostedFieldsEl, CSS_CLASS_D_NONE);
        }

        this.renderCardComponent();

        if (this.isGuestFlowRunOnce) {
            $('body').on('checkout:updateCheckoutView', this.handlePhoneNumberPrefill.bind(this));
            $('body').on('shipping:selectMultiShipping', this.showFormForMultishipFlow.bind(this));

            this.isGuestFlowRunOnce = false;
        }
    }

    /**
     * Handle phone number prefill
     * @param {Object} _ - Event object
     * @param {Object} data - Response data
     */
    handlePhoneNumberPrefill(_, data) {
        const phone = data?.order?.billing?.billingAddress?.address?.phone;

        if (phone && !this.#isPhonePrefilled) {
            this.renderCardComponent();
        }
    }

    /**
     * Handle user flow
     */
    handleUserFlow() {
        sessionStorage.setItem(FASTLANE_USER_ROLE, ROLE_USER);

        fastlaneHelper.addClass(this.hostedFieldsEl, CSS_CLASS_D_NONE);

        this.shippingAddressHandler(this.profileData.shippingAddress);
        this.cardHandler();

        if (this.isUserFlowRunOnce) {
            $('body').on('checkout:updateCheckoutView', this.handleUpdateCheckoutView.bind(this));
            $('body').on('shipping:selectMultiShipping', this.handleMultishipSelect.bind(this));
            $('body').on('shipping:selectSingleShipping', this.handleSingleshipSelect.bind(this));
            $('body').on('shipping:updateShippingAddressSelector', this.createMultishipSummaryWatermark.bind(this));

            this.addEventToButtonForChange();

            this.isUserFlowRunOnce = false;
        }
    }

    /**
     * Add event for change button
     */
    addEventToButtonForChange() {
        const changeAddressEl = document.querySelectorAll('.js-change-fastlane-address');
        const changeCardEl = document.getElementById('js-change-fastlane-card');

        Array.from(changeAddressEl).forEach(el => {
            el.addEventListener('click', this.changeAddressHandler.bind(this));
        });

        changeCardEl?.addEventListener('click', this.changeCardHandler.bind(this));
    }

    /**
     * Handles change card event for Fastlane checkout
     * @param {event} event current event
     */
    async changeCardHandler(event) {
        fastlaneHelper.applyPreventEventMethods(event);

        const {
            selectionChanged,
            selectedCard
        } = await this.profile.showCardSelector();

        if (selectionChanged) {
            const profileName = this.profileData.name;
            const cardData = selectedCard.paymentSource.card;
            const phoneNumber = this.profileData.shippingAddress?.phoneNumber ?? fastlaneHelper.getShippingPhone();

            fastlaneHelper.handleBillingAddressUpdate(fastlaneHelper.prepareAddressData(cardData.billingAddress, profileName, phoneNumber));
            fastlaneHelper.setCardData(cardData, selectedCard.id);
            fastlaneHelper.showCard(cardData);
        }
    }

    /**
     * Handles change address event for Fastlane checkout
     * @param {Object} event current event
     */
    async changeAddressHandler(event) {
        fastlaneHelper.applyPreventEventMethods(event);

        const {
            selectionChanged,
            selectedAddress
        } = await this.profile.showShippingAddressSelector();

        if (selectionChanged) {
            this.shippingAddress = fastlaneHelper.prepareAddressData(selectedAddress);

            if (this.multishipCheckbox) {
                fastlaneHelper.handleAddressUpdate(this.secondShippingForm, this.shippingAddress, this.secondAddressContainer);
            }

            fastlaneHelper.handleAddressUpdate(this.firstShippingForm, this.shippingAddress, this.firstAddressContainer);
        }
    }

    /**
     * Get customer email value
     * @returns {string} - customer email
     */
    getCustomerEmail() {
        const guestEmailEl = document.getElementById('email-guest');
        const customerEmailEl = document.querySelector('.customer-summary-email');

        return guestEmailEl?.value || customerEmailEl?.textContent;
    }

    /**
     * Validates the email input field.
     * @returns {boolean} Returns true if the email field has a value and is valid, otherwise false.
     */
    checkEmailField() {
        const fieldEl = document.getElementById('email-guest');

        return fieldEl?.value && fieldEl.checkValidity();
    }

    /**
     * Set device data into html field
     */
    setDeviceData() {
        const deviceDataEl = document.getElementById('braintreeDeviceData');

        if (deviceDataEl) {
            deviceDataEl.value = this.dataCollector.deviceData;
        }
    }

    /**
     * Triggers click on 'Continue as guest' button on customer step
     */
    triggerContinueAsGuestClick() {
        if (this.isCheckoutFromBeginning) {
            this.lookupCustomerButtonEl.disabled = false;
            this.lookupCustomerButtonEl.click();
            this.lookupCustomerButtonEl.disabled = true;
        }
    }

    /**
     * Handles card list select change
     */
    cardListChange() {
        const selectedOption = fastlaneHelper.getSelectedOption(this.cardListEl);

        switch (selectedOption.id) {
            case 'newCardAccount':
                fastlaneHelper.removeClass(this.walletContainerEl, CSS_CLASS_D_NONE);
                fastlaneHelper.removeClass(this.hostedFieldsEl, CSS_CLASS_D_NONE);

                break;

            case 'fastlaneSessionCreditCard':
                fastlaneHelper.addClass(this.walletContainerEl, CSS_CLASS_D_NONE);
                fastlaneHelper.addClass(this.hostedFieldsEl, CSS_CLASS_D_NONE);

                break;
        }
    }

    /**
     * Creates params from fastlane hosted fields render
     * @returns {Object} - params object
     */
    createFieldsParams() {
        const billingData = helper.getBillingAddressFormValues();
        const phoneNumber = billingData.phone || fastlaneHelper.getShippingPhone();

        this.#isPhonePrefilled = !!phoneNumber;

        const fieldParams = {
            phoneNumber: {
                prefill: phoneNumber
            },
            cardholderName: {
                enabled: this.isCardholderNameEnabled
            }
        };

        if (this.isCardholderNameEnabled) {
            fieldParams.cardholderName.prefill = fastlaneHelper.getCardholderName();
        }

        return fieldParams;
    }

    /**
     * Gets array with allowed for shipping countries/states
     * @returns {Array} - allowed countries/states array
     */
    getAllowedCountries() {
        const allowedCountryOptions = document.querySelector('.shippingCountry')?.options;
        const allowedStateOptions = document.querySelector('.shippingState')?.options;

        if (!allowedCountryOptions) {
            return [];
        }

        return Array.from(allowedCountryOptions).reduce((allowedLocations, country) => {
            if (country.id) {
                const isUSCountry = country.id === 'US';

                if (isUSCountry && allowedStateOptions) {
                    Array.from(allowedStateOptions).forEach((state) => {
                        if (state.id && state.id.toLowerCase() !== 'other') {
                            allowedLocations.push(`${country.id}:${state.id}`);
                        }
                    });
                } else {
                    allowedLocations.push(country.id);
                }
            }

            return allowedLocations;
        }, []);
    }

    /**
     * Handles multiship flow for Fastlane user
     * @param {Object} shippingAddress - shipping address used for update
     */
    handleMultiship(shippingAddress) {
        const elementException = 2;

        fastlaneHelper.addClass(this.secondShipmentSelectorBlockEl, CSS_CLASS_D_NONE);

        this.secondBtnEnterMultiship.click();

        fastlaneHelper.hideFastlaneAddressBlock(this.addressContainerAllEls, elementException);

        const secondFastlanePhoneNumberEl = fastlaneHelper.getSecondElement(document.querySelectorAll('.fastlane-phone-number'));

        this.addAddressWatermark(secondFastlanePhoneNumberEl);

        fastlaneHelper.showShippingAddressFormBlock(elementException);
        fastlaneHelper.handleAddressUpdate(this.secondShippingForm, shippingAddress, this.secondAddressContainer);
        fastlaneHelper.removeClass(this.secondAddressContainer, CSS_CLASS_D_NONE);
    }

    /**
     * Shows shipping form when multiship flow for Fastlane guest user
     */
    showFormForMultishipFlow() {
        if (fastlaneHelper.isFastlaneRoleGuest()) {
            const elementException = 1;

            fastlaneHelper.showShippingAddressFormBlock(elementException);
        }
    }

    /**
     * Handles multiship select event flow
     */
    handleMultishipSelect() {
        if (fastlaneHelper.isFastlaneRoleUser() && this.shippingAddress) {
            this.handleMultiship(this.shippingAddress);
        } else {
            this.showFormForMultishipFlow();
        }
    }

    /**
     * Handles singleship select event flow
     */
    handleSingleshipSelect() {
        if (fastlaneHelper.isFastlaneRoleUser()) {
            fastlaneHelper.handleAddressUpdate(this.firstShippingForm, this.shippingAddress, this.firstAddressContainer);
        }
    }

    /**
     * Handles update checkout view event
     */
    handleUpdateCheckoutView() {
        if (fastlaneHelper.isFastlaneRoleUser()) {
            if (this.profileData?.card) {
                fastlaneHelper.setCardNumber(this.profileData.card.paymentSource?.card?.lastDigits);

                this.addCardSummaryWatermark();
            }

            fastlaneHelper.handleAddressUpdate(
                this.firstShippingForm, this.shippingAddress, this.firstAddressContainer);

            if (this.multishipCheckbox) {
                fastlaneHelper.handleAddressUpdate(
                    this.secondShippingForm, this.shippingAddress, this.secondAddressContainer);
            }

            if (this.billingAddress) {
                fastlaneHelper.handleBillingAddressUpdate(this.billingAddress);
            }

            this.createMultishipSummaryWatermark();
            this.createMultishipGeneralSummaryWatermark();
        }
    }

    /**
     * Saves customer email into session
     * @param {string} customerEmail - customer's email
     */
    saveCustomerEmail(customerEmail) {
        sessionStorage.setItem(FASTLANE_EMAIL, customerEmail);
    }

    /**
     * Handles customer login step: compares used for login email address and clears session payment if needed
     * @param {string} customerEmail - customer's email
     */
    handleCustomerLogin(customerEmail) {
        const usedCustomerEmail = sessionStorage.getItem(FASTLANE_EMAIL);

        if (!usedCustomerEmail) {
            this.saveCustomerEmail(customerEmail);
        } else if (usedCustomerEmail !== customerEmail) {
            helper.removeActiveSessionPayment(true);

            this.saveCustomerEmail(customerEmail);
        }
    }

    createMultishipSummaryWatermark() {
        const multishipShipBlockEls = document.querySelectorAll('.shipping-section div.multi-shipping .card');
        const firstMultishipShipBlockEl = fastlaneHelper.getFirstElement(multishipShipBlockEls);

        if (firstMultishipShipBlockEl) {
            this.dataShipmentUUID = firstMultishipShipBlockEl.getAttribute('data-shipment-uuid');
            this.productLineItemUUID = firstMultishipShipBlockEl.querySelector('input[name=productLineItemUUID]')?.value;

            this.addAddressWatermark(firstMultishipShipBlockEl.querySelector(CSS_CLASS_SHIP_TO_PHONE));
        }
    }

    createMultishipGeneralSummaryWatermark() {
        const summaryMultishipBlockEls = document.querySelectorAll('.order-product-summary .multi-shipping');
        const orderProductSummaryEls = document.querySelectorAll('.order-product-summary .shipment-block');

        if (summaryMultishipBlockEls.length && this.dataShipmentUUID) {
            summaryMultishipBlockEls.forEach(block => {
                if (block.getAttribute('data-shipment-summary') === this.dataShipmentUUID) {
                    this.addAddressWatermark(block.querySelector(CSS_CLASS_SHIP_TO_PHONE));
                }
            });
        } else if (orderProductSummaryEls.length && this.productLineItemUUID) {
            orderProductSummaryEls.forEach(el => {
                Array.from(el.querySelectorAll('.product-line-item')).forEach(item => {
                    if (item.getAttribute('data-product-line-item') === this.productLineItemUUID) {
                        this.addAddressWatermark(el.querySelector(CSS_CLASS_SHIP_TO_PHONE));
                    }
                });
            });
        }
    }

    /**
     * Adds watermark after specific address element, creates watermark if is not created already
     * @param {HTMLElement} insertAfterEl - element after which watermark must be inserted
     */
    addAddressWatermark(insertAfterEl) {
        if (!insertAfterEl) {
            return;
        }

        const watermarkName = 'fastlane-watermark';
        const watermarkEl = document.getElementById(watermarkName);

        if (!watermarkEl) {
            const createdDiv = document.createElement('div');

            createdDiv.id = watermarkName;
            createdDiv.className = 'watermark-container';

            insertAfterEl.parentNode.insertBefore(createdDiv, createdDiv.nextSibling);

            this.renderWatermark(document.getElementById(watermarkName));
        } else {
            const nextElementSibling = insertAfterEl.nextElementSibling;

            if (nextElementSibling?.id === watermarkName) {
                if (nextElementSibling.classList.contains(CSS_CLASS_D_NONE)) {
                    fastlaneHelper.removeClass(nextElementSibling, CSS_CLASS_D_NONE);
                }

                return;
            }

            const clonedDiv = watermarkEl.cloneNode(true);

            insertAfterEl.parentNode.insertBefore(clonedDiv, clonedDiv.nextSibling);
        }
    }

    /**
     * Adds watermark after specific element on payment summary block
     * @param {HTMLElement} insertAfterEl - element after which watermark must be inserted
     */
    addCardSummaryWatermark() {
        if (this.cardWatermarkEl) {
            const creditCardTypeEL = document.querySelector('.credit-card-type');
            const btPaymentDetailsEl = document.querySelector('.js-braintree-payment-details');
            const paymentSummaryChildEls = btPaymentDetailsEl?.children;

            let insertAfterEl = creditCardTypeEL;

            if (btPaymentDetailsEl && paymentSummaryChildEls.length > 1) {
                insertAfterEl = paymentSummaryChildEls[paymentSummaryChildEls.length - 2];
            }

            if (!insertAfterEl || insertAfterEl.nextElementSibling?.classList.contains('card-watermark')) {
                return;
            }

            const clonedDiv = this.cardWatermarkEl.cloneNode(true);

            insertAfterEl.insertBefore(clonedDiv, clonedDiv.nextSibling);
        }
    }

    /**
     * Handles shipping address behavior, cases with and without address from Fastlane side
     * @param {Object} shippingAddress - shipping address from Fastlane side
     */
    shippingAddressHandler(shippingAddress) {
        if (shippingAddress) {
            this.addAddressWatermark(document.querySelector('.single-shipping .fastlane-phone-number'));
            this.addAddressWatermark(document.querySelector('.single-shipping .shipping-phone'));

            fastlaneHelper.showFastlaneAddressBlock(this.addressContainerAllEls);
            fastlaneHelper.hideShippingAddressFormBlock();

            this.shippingAddress = fastlaneHelper.prepareAddressData(shippingAddress);

            fastlaneHelper.handleAddressUpdate(this.firstShippingForm, this.shippingAddress, this.firstAddressContainer);

            if (this.multishipCheckbox?.checked) {
                this.handleMultiship(this.shippingAddress);
            } else if (!fastlaneHelper.nonFastlaneNonceExist() && this.checkoutMainEl.dataset.checkoutStage === 'shipping') {
                this.submitShippingButtonEl.click();
            }
        } else {
            fastlaneHelper.showShippingAddressFormBlock();
            fastlaneHelper.hideFastlaneAddressBlock(this.addressContainerAllEls);
            fastlaneHelper.hideAddressWatermark();

            if (this.multishipCheckbox?.checked) {
                this.showFormForMultishipFlow();
            }
        }
    }

    /**
     * Handles credit card behavior, cases for flexible/component with and without card data from Fastlane side
     */
    async cardHandler() {
        let cardData;
        let cardToken;

        const shippingAddress = this.profileData.shippingAddress;
        const profileName = this.profileData.name;
        const phoneNumber = shippingAddress?.phoneNumber ?? '';

        if (this.profileData?.card) {
            sessionStorage.setItem(FASTLANE_CARD, true);

            fastlaneHelper.addClass(this.billingAddressSelectorBlockEl, CSS_CLASS_D_NONE);
            fastlaneHelper.hideSessionCardBlock();

            if (!this.isPaymentUI) {
                cardData = this.profileData.card?.paymentSource?.card;
                cardToken = this.profileData.card.id;

                fastlaneHelper.removeClass(this.cardContainerEl, CSS_CLASS_D_NONE);
                fastlaneHelper.removeClass(this.cardWatermarkEl, CSS_CLASS_D_NONE);
                fastlaneHelper.removeClass(this.cardWatermarkContainerEl, CSS_CLASS_D_NONE);

                this.billingAddress = fastlaneHelper.prepareAddressData(cardData?.billingAddress, profileName, phoneNumber);

                fastlaneHelper.handleBillingAddressUpdate(this.billingAddress);
                fastlaneHelper.setCardData(cardData, cardToken);

                fastlaneHelper.showCard(cardData);
                this.createMultishipSummaryWatermark();
                this.createMultishipGeneralSummaryWatermark();
            } else {
                fastlaneHelper.removeClass(this.walletContainerEl, CSS_CLASS_D_NONE);

                await this.renderPaymentComponent();

                if (this.paymentComponent) {
                    let tokenizationObject = {};

                    if (!this.isCardholderNameEnabled) {
                        tokenizationObject.cardholderName = fastlaneHelper.getCardholderName();
                    }

                    const paymentData = await this.paymentComponent.getPaymentToken(tokenizationObject);

                    cardData = paymentData.paymentSource.card;
                    cardToken = paymentData.id;
                }
            }

            this.renderWatermark(this.cardWatermarkEl);
            this.addCardSummaryWatermark();
            fastlaneHelper.removeClass(this.cardWatermarkEl, CSS_CLASS_D_NONE);

            this.billingAddress = fastlaneHelper.prepareAddressData(cardData?.billingAddress, profileName, phoneNumber);

            fastlaneHelper.handleBillingAddressUpdate(this.billingAddress);
            fastlaneHelper.setCardData(cardData, cardToken);
        } else {
            sessionStorage.setItem(FASTLANE_CARD, false);
            fastlaneHelper.removeClass(this.billingAddressSelectorBlockEl, CSS_CLASS_D_NONE);
            fastlaneHelper.clearElementContent(this.walletContainerEl);

            await this.renderCardComponent();

            if (this.isPaymentUI) {
                fastlaneHelper.addClass(this.billingAddressSelectorBlockEl, CSS_CLASS_D_NONE);
            }

            fastlaneHelper.removeClass(this.hostedFieldsEl, CSS_CLASS_D_NONE);
            fastlaneHelper.addClass(this.cardContainerEl, CSS_CLASS_D_NONE);
            fastlaneHelper.addClass(this.walletContainerEl, CSS_CLASS_D_NONE);
            fastlaneHelper.addClass(this.cardWatermarkEl, CSS_CLASS_D_NONE);
            fastlaneHelper.addClass(this.cardWatermarkContainerEl, CSS_CLASS_D_NONE);
        }
    }

    /**
     * Handles payment data submit, updates necessary fields and data to submit payment
     */
    async submitGuestPayment() {
        const tokenizationObject = fastlaneHelper.formatBillingAddress();

        if (!this.isCardholderNameEnabled) {
            tokenizationObject.cardholderName = fastlaneHelper.getCardholderName();
        }

        const { paymentSource, id } = await this.cardComponent.getPaymentToken(tokenizationObject);
        const cardData = paymentSource.card;

        fastlaneHelper.fillFormWithPayloadData(cardData, id);

        fastlaneHelper.setSessionCardAttributes(cardData, id);
        fastlaneHelper.showSessionCardBlock();
        fastlaneHelper.setSessionCardData();

        if (this.isPaymentUI) {
            fastlaneHelper.handleBillingAddressUpdate(fastlaneHelper.prepareAddressData(cardData?.billingAddress,
                cardData?.name,
                fastlaneHelper.getBillingPhone()));
        }

        fastlaneHelper.setBillingAddress(fastlaneHelper.createBillingAddress());

        this.renderCardComponent();
    }
}

module.exports = Fastlane;
