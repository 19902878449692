'use strict';

// Braintree SDK model builder, we need to init Braintree Credit Card model on this level, since we are using it on step of "creditCardFields.init()"
// and on step of "creditCardPayment.init()".

var creditCardFields = require('./components/creditCardFields');

var HostedFieldsModel = require('../creditcard/braintreesdk/hostedFieldsModel');
var CreditCardAlertHandlerModel = require('../creditcard/alertHandler/creditCardAlertHandlerModel');

var hostedFieldsInstance = new HostedFieldsModel();

var btCreditCardModelResponse;
var creditCardMessages;

/**
 * Inits Credit Card functionality on the Account Page
 * @returns {void}
 */
function initAccount() {
    var creditCardAccount = require('./components/creditCardAccount');

    var alertHandler = new CreditCardAlertHandlerModel(creditCardMessages);
    var { basicFlowConfigs, styles } = hostedFieldsInstance.getFieldsOptions();

    // Sets an appropriate hosted fields options object for Account page
    hostedFieldsInstance.setHostedFieldsOptions({
        styles: styles,
        configs: basicFlowConfigs
    });

    if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
        alertHandler.handleCreditCardError(btCreditCardModelResponse.message);
    }

    creditCardAccount.init(btCreditCardModelResponse.btCreditCardModel,
        alertHandler,
        creditCardMessages,
        hostedFieldsInstance);

    creditCardFields.init(btCreditCardModelResponse.btCreditCardModel,
        alertHandler,
        hostedFieldsInstance);
}

/**
 * Inits Credit Card functionality on the Checkout Page
 * @returns {void}
 */
async function initCheckout() {
    var creditCardList = require('./components/creditCardList');
    var creditCardPaymentProcessing = require('./components/creditCardPaymentProcessing');

    var alertHandler = new CreditCardAlertHandlerModel(creditCardMessages);
    // Hosted fields options initializations
    var fieldsOptions = hostedFieldsInstance.getFieldsOptions();
    var isReVerifyFlow = hostedFieldsInstance.hostedFieldsConfigs.isCcReVerifyEnabled && hostedFieldsInstance.hostedFieldsConfigs.isSavedCreditCard;
    var configs = isReVerifyFlow ? fieldsOptions.reverifyFlowConfigs : fieldsOptions.basicFlowConfigs;

    // Sets an appropriate hosted fields options object for Checkout page
    hostedFieldsInstance.setHostedFieldsOptions({
        styles: fieldsOptions.styles,
        configs
    });

    if (!btCreditCardModelResponse || btCreditCardModelResponse.error) {
        alertHandler.handleCreditCardError(btCreditCardModelResponse.message);
    }

    // Call server to update data from basket
    await btCreditCardModelResponse.btCreditCardModel.updateOrderData();

    creditCardFields.init(btCreditCardModelResponse.btCreditCardModel,
        alertHandler,
        hostedFieldsInstance);

    creditCardList.init(btCreditCardModelResponse.btCreditCardModel,
        alertHandler,
        hostedFieldsInstance);

    creditCardPaymentProcessing.init(alertHandler,
        hostedFieldsInstance);

    creditCardFields.addNewCardModalTriggerInputID();
}

/**
 * Inits 'braintreeCreditCardModelBuilder' on creditCard level
 */
function init() {
    var braintreeCreditCardModelBuilder = require('./braintreesdk/braintreeCreditCardModelBuilder');

    btCreditCardModelResponse = braintreeCreditCardModelBuilder.init();

    if (!btCreditCardModelResponse.error) {
        creditCardMessages = btCreditCardModelResponse.btCreditCardConfigs.messages;
    }
}

module.exports = {
    initAccount,
    initCheckout,
    init
};
