'use strict';

const PRICE_SELECTOR = '.price .sales .value';
const MINI_CART_QUANTITY_SELECTOR = '.minicart-quantity';
const PRODUCT_STATUS_UPDATE = 'product:statusUpdate';
const CART_STATUS_UPDATE = 'cart:update';
const ADD_TO_CART_BTN_SELECTOR = '.js-add-to-cart, .js-add-to-cart-global';
const PAYPAL_BANNER_SELECTOR = '.js-pp-banner';

/**
 * Hide PayPal button
 * @param {Object} $payPalButton PayPal button js container
 */
function hidePayPalButton($payPalButton) {
    $payPalButton.style.display = 'none';
}

/**
 * Show PayPal button
 * @param {Object} $payPalButton PayPal button js container
 */
function showPayPalButton($payPalButton) {
    $payPalButton.style.display = '';
}

/**
 * Check if PDP product price is zero
 * @returns {boolean} "true" in case if PDP product price is zero
 */
function isZeroPdpProductPrice() {
    const $productPriceSelector = document.querySelector(PRICE_SELECTOR);

    if ($productPriceSelector) {
        const price = parseInt($productPriceSelector.getAttribute('content'), 0);

        return price <= 0;
    }

    return true;
}

/**
 * Is add to cart button disabled
 * @returns {boolean} "true" in case if add to cart button disabled
 */
function isAddToCartButtonDisabled() {
    const $addToCartButton = document.querySelector('.js-add-to-cart, .js-add-to-cart-global');

    return $addToCartButton.disabled;
}

/**
 * Return mini cart quantity
 * @param {Object} $miniCartQuantitySelector Mini cart quantity selector
 * @returns {Int} Quantity
 */
function getMiniCartQuantity($miniCartQuantitySelector) {
    let quantity = null;

    if ($miniCartQuantitySelector) {
        quantity = parseInt($miniCartQuantitySelector.textContent, 0);
    }

    return quantity;
}

/**
 * Check if basket is empty
 * @returns {boolean} "true" in case if basket is not empty
 */
function isBasketNotEmpty() {
    const miniCartQuantityElement = document.querySelector(MINI_CART_QUANTITY_SELECTOR);

    return getMiniCartQuantity(miniCartQuantityElement) !== 0;
}

/**
 * Check if current total basket price is zero
 * @returns {boolean} "true" in case if current total basket price is zero
 */
function isCurrentTotalBasketPriceZero() {
    const $basketTotalAmount = document.querySelector('.js-sub-total');

    if ($basketTotalAmount) {
        const basketTotalAmount = $basketTotalAmount.textContent.slice(1);

        return parseInt(basketTotalAmount, 10) === 0;
    }

    return false;
}

/**
 * Updates PayPal credit message amount
 * @param {Object} productData - product info after update
 * @param {Element} $creditMessage - credit message element
 * @returns {void}
 */
function updateCreditMessageAmount(productData, $creditMessage) {
    const { price, selectedQuantity } = productData.data?.product || productData;
    const productPrice = price.max?.sales?.value || price.sales.value;

    const newPrice = parseFloat(productPrice) * parseFloat(selectedQuantity);

    $creditMessage.setAttribute('data-pp-amount', newPrice);
}

/**
 * Show or hide PayPal button
 * @param {Object} paypalMinicartButton paypal button js container
 */
function paypalMinicartButtonBehavior(paypalMinicartButton) {
    if (!isCurrentTotalBasketPriceZero()) {
        showPayPalButton(paypalMinicartButton);
    } else {
        hidePayPalButton(paypalMinicartButton);
    }
}

/**
 * Handles PP credit messages behavior on PDP
 * @returns {void}
 */
function initPaypalCreditMessagesBehaviorOnPdp() {
    $('body').on('product:afterAttributeSelect', function(_, productData) {
        const $creditMessage = document.querySelector(PAYPAL_BANNER_SELECTOR);

        // If PayPal credit message is available, then update credit message amount
        if ($creditMessage) {
            updateCreditMessageAmount(productData, $creditMessage);
        }
    });
}

/**
 * Handles PP credit messages behavior on PDP for Product bandle
 * @returns {void}
 */
function initPaypalCreditMessagesBehaviorOnPdpBundle() {
    const $price = document.querySelector(PRICE_SELECTOR);
    const $quantitySelector = document.querySelector('.quantity-select');
    const $creditMessage = document.querySelector(PAYPAL_BANNER_SELECTOR);

    if ($creditMessage) {
        $quantitySelector.addEventListener('change', (event) => {
            const price = parseFloat($price.getAttribute('content'));
            const quantity = parseFloat(event.target.value);

            const newPrice = price * quantity;

            $creditMessage.setAttribute('data-pp-amount', newPrice);
        });
    }
}

/**
 * Handles PP credit messages behavior on PDP for Product set
 * @returns {void}
 */
function initPaypalCreditMessagesBehaviorOnPdpSet() {
    $('body').on('product:afterAttributeSelect', function(_, productData) {
        const $productIds = document.querySelectorAll('.product-id');
        const $targerProduct = Array.from($productIds).find((el) => el.textContent === productData.data.product.id);
        const $setItemContainer = $targerProduct && $targerProduct.closest('.set-item');
        const $creditMessage = $setItemContainer && $setItemContainer.querySelector(PAYPAL_BANNER_SELECTOR);

        // If PayPal credit message is available, then update credit message amount
        if ($creditMessage) {
            updateCreditMessageAmount(productData, $creditMessage);
        }
    });
}

/**
 * Handles PP button behavior on PDP
 * @param {Object} $payPalButton - paypal button element
 * @returns {void}
 */
function initPaypalButtonBehaviorOnPdp($payPalButton) {
    let addToCartButtonDisabled = isAddToCartButtonDisabled();
    let zeroProductPrice = isZeroPdpProductPrice();
    let basketNotEmpty = isBasketNotEmpty();

    if (addToCartButtonDisabled || zeroProductPrice || basketNotEmpty) {
        hidePayPalButton($payPalButton);
    }

    $('body').on('product:afterAddToCart', function() {
        hidePayPalButton($payPalButton);
    });

    // Case when buyer remove product from Cart
    $('body').on(CART_STATUS_UPDATE, function() {
        const addToCartButtonEnabled = !isAddToCartButtonDisabled();

        zeroProductPrice = isZeroPdpProductPrice();
        basketNotEmpty = isBasketNotEmpty();

        if (!basketNotEmpty && addToCartButtonEnabled && !zeroProductPrice) {
            showPayPalButton($payPalButton);
        } else {
            hidePayPalButton($payPalButton);
        }
    });

    // When buyer change some attribute of the product on PDP
    $('body').on(PRODUCT_STATUS_UPDATE, function() {
        addToCartButtonDisabled = isAddToCartButtonDisabled();
        zeroProductPrice = isZeroPdpProductPrice();
        basketNotEmpty = isBasketNotEmpty();

        if (addToCartButtonDisabled || zeroProductPrice || basketNotEmpty) {
            hidePayPalButton($payPalButton);
        } else {
            showPayPalButton($payPalButton);
        }
    });
}

/**
 * Hides all PayPal buttons on Set.
 * @param {string} selector - The selector for the PayPal button.
 * @returns {void}
 */
function hideAllPayPalButtonsOnSet(selector) {
    const addToCartButtons = document.querySelectorAll(ADD_TO_CART_BTN_SELECTOR);

    addToCartButtons.forEach((btn) => {
        const paypalButton = btn.closest('.attributes, .prices-add-to-cart-actions').querySelector(selector);

        hidePayPalButton(paypalButton);
    });
}

/**
 * Handling PVP button behavior on Quick View
 * @param {Object} $payPalButton - paypal button element
 * @returns {void}
 */
function initPaypalButtonBehaviorOnPvp($payPalButton) {
    const isProductReadyToOrderElement = document.querySelector('.js-is-show-pp-button');
    const isProductReadyToOrder = isProductReadyToOrderElement && isProductReadyToOrderElement.value === 'true';

    let addToCartButtonDisabled = isAddToCartButtonDisabled();
    let zeroProductPrice = isZeroPdpProductPrice();
    let basketNotEmpty = isBasketNotEmpty();

    if (addToCartButtonDisabled || zeroProductPrice || basketNotEmpty || !isProductReadyToOrder) {
        hidePayPalButton($payPalButton);
    }

    $('body').on(PRODUCT_STATUS_UPDATE, function() {
        basketNotEmpty = isBasketNotEmpty();
        addToCartButtonDisabled = isAddToCartButtonDisabled();
        zeroProductPrice = isZeroPdpProductPrice();

        if (addToCartButtonDisabled || zeroProductPrice || basketNotEmpty) {
            hidePayPalButton($payPalButton);
        } else {
            showPayPalButton($payPalButton);
        }
    });
}

/**
 * Handling PP button behavior on Product Set
 * @param {string} ppBtnSelector - selector for PP buttons on product set
 * @returns {void}
 */
function initPaypalButtonBehaviorOnSet(ppBtnSelector) {
    const PP_BTN_CONTAINER_SELECTOR = '.attributes, .prices-add-to-cart-actions, .global-availability';
    const addToCartButtons = document.querySelectorAll(ADD_TO_CART_BTN_SELECTOR);

    let basketNotEmpty = isBasketNotEmpty();

    addToCartButtons.forEach((btn) => {
        const paypalButton = btn.closest(PP_BTN_CONTAINER_SELECTOR).querySelector(ppBtnSelector);
        const isProductReadyToOrderElement = btn.closest(PP_BTN_CONTAINER_SELECTOR).querySelector('.js-is-show-pp-button');
        const isProductReadyToOrder = isProductReadyToOrderElement && isProductReadyToOrderElement.value === 'true';

        const prices = document.querySelectorAll(PRICE_SELECTOR);

        let isWrongAmount;

        if (prices) {
            isWrongAmount = Array.from(prices).some((price) => {
                return parseFloat(price.getAttribute('content')) <= 0;
            });
        }

        if (!basketNotEmpty && !isWrongAmount && isProductReadyToOrder && !btn.disabled) {
            showPayPalButton(paypalButton);
        } else {
            hidePayPalButton(paypalButton);
        }
    });

    $('body').on(PRODUCT_STATUS_UPDATE, function(event) {
        const addToCartButton = event.target;

        const prices = document.querySelectorAll(PRICE_SELECTOR);
        const paypalButton = addToCartButton.closest(PP_BTN_CONTAINER_SELECTOR).querySelector(ppBtnSelector);

        let isWrongAmount;

        basketNotEmpty = isBasketNotEmpty();

        if (prices) {
            isWrongAmount = Array.from(prices).some((price) => {
                return parseFloat(price.getAttribute('content')) <= 0;
            });
        }

        if (!basketNotEmpty && !isWrongAmount && !addToCartButton.disabled) {
            showPayPalButton(paypalButton);
        } else {
            hidePayPalButton(paypalButton);
        }
    });
}

/**
 * Handles PayPal button behavior on PDP for Product set.
 * @param {string} selector - The selector for the PayPal button.
 * @returns {void}
 */
function initPaypalButtonBehaviorOnPdpSet(selector) {
    initPaypalButtonBehaviorOnSet(selector);

    $('body').on('product:afterAddToCart', function() {
        hideAllPayPalButtonsOnSet(selector);
    });

    // Case when buyer remove product from Cart
    $('body').on(CART_STATUS_UPDATE, function() {
        const addToCartButtons = document.querySelectorAll(ADD_TO_CART_BTN_SELECTOR);
        const basketNotEmpty = isBasketNotEmpty();

        addToCartButtons.forEach((btn) => {
            const paypalButton = btn.closest('.attributes, .prices-add-to-cart-actions').querySelector(selector);

            const prices = document.querySelectorAll(PRICE_SELECTOR);

            let isWrongAmount;

            if (prices) {
                isWrongAmount = Array.from(prices).some((price) => {
                    return parseFloat(price.getAttribute('content')) <= 0;
                });
            }

            if (!basketNotEmpty && !isWrongAmount && !btn.disabled) {
                showPayPalButton(paypalButton);
            } else {
                hidePayPalButton(paypalButton);
            }
        });
    });
}

/**
 * Update Pay Later message amount for Cart, Category pages
 * @param {Object} _ - Event data
 * @param {Object} data - Data passed by trigger
 * @returns {void}
 */
function updatePayLaterMessageAmountForCart(_, data) {
    const ppMessageEl = document.querySelector(PAYPAL_BANNER_SELECTOR);
    let total;
    let checkElementAgain;

    if ('cart' in data) {
        total = data.cart.totals.grandTotal;
    } else if ('basket' in data) {
        total = data.basket.totals.grandTotal;
        checkElementAgain = data.basket.items.length === 0;
    } else {
        total = data.totals.grandTotal;
    }

    if (checkElementAgain && !ppMessageEl) {
        return;
    }

    ppMessageEl.dataset.ppAmount = parseFloat(total.replace(/[^0-9.]/g, ''));
}

/**
 * Handles Credit messages behavior for cart/category page
 * @returns {void}
 */
function initPaypalCreditMessagesBehavior() {
    const paypalCreditBanner = document.querySelector(PAYPAL_BANNER_SELECTOR);

    if (paypalCreditBanner) {
        $('body')
            .on(CART_STATUS_UPDATE, updatePayLaterMessageAmountForCart)
            .on('count:update', updatePayLaterMessageAmountForCart);
    }
}

/**
 * Init PayPal button display behavior. This code is handle only PayPal button on PVP page
 * @returns {void}
 */
function initProductViewPage() {
    const PP_BUTTONS_SELECTOR = '.js-braintree-pvp-button, .js-braintree-pvp-button-global';

    const paypalButton = document.querySelector('.quick-view-dialog .js-braintree-pvp-button-global');
    const isProductSet = Boolean(document.querySelector('.set-items'));

    if (isProductSet) {
        initPaypalButtonBehaviorOnSet(PP_BUTTONS_SELECTOR);
    } else if (paypalButton) {
        initPaypalButtonBehaviorOnPvp(paypalButton);
    }
}

/**
 * Init PayPal button display behavior on PDP.
 */
function initProductPage() {
    const PP_BUTTONS_SELECTOR = '.js-braintree-pdp-button, .js-braintree-pdp-button-global';

    const paypalButton = document.querySelector(PP_BUTTONS_SELECTOR);
    const paypalCreditBanner = document.querySelector(PAYPAL_BANNER_SELECTOR);
    const isProductSet = Boolean(document.querySelector('.set-items'));
    const isProductBundle = Boolean(document.querySelector('.product-bundle'));

    if (isProductSet) {
        initPaypalButtonBehaviorOnPdpSet(PP_BUTTONS_SELECTOR);
    } else if (paypalButton) {
        initPaypalButtonBehaviorOnPdp(paypalButton);
    }

    if (paypalCreditBanner) {
        if (isProductSet) {
            initPaypalCreditMessagesBehaviorOnPdpSet();
        } else if (isProductBundle) {
            initPaypalCreditMessagesBehaviorOnPdpBundle();
        } else {
            initPaypalCreditMessagesBehaviorOnPdp();
        }
    }
}

module.exports = {
    initProductPage,
    initProductViewPage,
    paypalMinicartButtonBehavior,
    initPaypalCreditMessagesBehavior
};
