'use strict';

const AlertHandlerModel = require('base_braintree/braintree/alertHandler');
const creditCardFormFieldValidation = require('./creditCardFormFieldsValidator');

/**
 * Credit card alert handler model
 */
class CreditCardAlertHandlerModel extends AlertHandlerModel {
    constructor(alertMessages) {
        super();
        this.alertMessages = alertMessages;
    }

    /**
     * Converts Braintree fields
     * @param {Array} arrayOfBtFormFields Credit Cards hosted fileds in array
     * @returns {Array} Converted array
     */
    convertBtFromFieldsToDw(arrayOfBtFormFields) {
        const helper = require('../../helper');
        const creditCardHelper = require('../helpers/creditCardHelper');
        const hostedFieldsConfig = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();

        const creditCardFieldsMap = hostedFieldsConfig.fields.reduce((accumulator, field) => {
            accumulator[field] = 'braintree-' + helper.toKebabCase(field);

            return accumulator;
        }, {});

        return arrayOfBtFormFields.map((fieldName) => creditCardFieldsMap[fieldName]);
    }

    /**
     * Handles all fields are empty behavior
     */
    allFieldsAreEmptyErrorBehavior() {
        creditCardFormFieldValidation.makeAllFormFieldsInvalid(this.ccFields);
    }

    /**
     * Handles some fileds are empty behavior
     * @param {Array} btInvalidHostedFields Invalid fileds
     */
    someFieldsAreInvalidErrorBehavior(btInvalidHostedFields) {
        const convertedFormFields = this.convertBtFromFieldsToDw(btInvalidHostedFields);

        creditCardFormFieldValidation.makeFormFieldsInvalid(convertedFormFields, this.ccFields);
    }

    /**
     * Process and validates case when buyer left hosted feieds empty
     * @param {Object} errorPayload Payload with error information
     * @param {string} braintreeErrorCode Error code
     */
    processBraintreeHostedFiledsErrors(errorPayload, braintreeErrorCode) {
        const ALL_HOSTED_FIELDS_FIELDS_EMPTY = 'HOSTED_FIELDS_FIELDS_EMPTY';
        const HOSTED_FIELDS_FIELDS_INVALID = 'HOSTED_FIELDS_FIELDS_INVALID';

        switch (braintreeErrorCode) {
            case ALL_HOSTED_FIELDS_FIELDS_EMPTY:
                this.allFieldsAreEmptyErrorBehavior();

                break;

            case HOSTED_FIELDS_FIELDS_INVALID: {
                const invalidFields = errorPayload.details.invalidFieldKeys;

                this.someFieldsAreInvalidErrorBehavior(invalidFields);

                break;
            }

            default:
                break;
        }
    }

    /**
     * Handle "Credit Card" error cases.
     * Cases can be split on Custom (errors trigered via "Promise.reject" from our side)
     * and on Braintree (errors from Braintree Client SDK)
     * @param {Object} error Error payload from ".catch()" block
     * @param {Object} ccFieldsInitiated An object with initiated hosted fields
     */
    handleCreditCardError(error, ccFieldsInitiated) {
        if (error.name === 'BraintreeError') {
            this.ccFields = ccFieldsInitiated;
            // Puts error on the top of the Credit Page
            this.showBraintreeError(error);
            this.processBraintreeHostedFiledsErrors(error, error.code);
        } else {
            let errorMsg;

            // Handles the cases when 3ds secure failed
            if (error.customError) {
                errorMsg = this.alertMessages.secure3DFailed;
            // Not braintree errors
            } else {
                errorMsg = error.message ? error.message : error;

                const showDetailsBtn = document.querySelector(
                    '.billing-address-block .address-selector-block .form-group .row .btn-show-details'
                );

                // Highlights the invalid fields to the buyer
                if (showDetailsBtn) {
                    showDetailsBtn.click();
                }
            }

            this.showError(errorMsg);
        }
    }
}

module.exports = CreditCardAlertHandlerModel;
