'use strict';

const helper = require('../../helper');

let braintreeCreditCardModel;
let addNewCreditCardLoader;
let alertHandler;
let ccMessages;

let hfFieldsInstance;

/**
 * Closes js-braintree-add-new-card-block & clears hosted fields input data
 */
function closeAddNewCardBlock() {
    const $addNewCardButton = document.querySelector('.js-braintree-add-new-card-btn');
    const $addNewCardBlock = document.querySelector('.js-braintree-add-new-card-block');

    // Hide "Add New Card" form
    $addNewCardBlock.classList.add('d-none');
    // Show "Add New Card" button
    $addNewCardButton.classList.remove('d-none');
    // Clear hosted fields input data
    hfFieldsInstance.clearHostedFields();
}

/**
 * Shows message if Credit Card was updated
 * @param {Object} data addCreditCard response
 */
function showCardUpdateMessage(data) {
    if (data.isCardNumberUpdated) {
        alertHandler.showInfo(data.cardNumberUpdatedMessage);
    }
}

/**
 * Stores new Credit Card on account page
 * @param {Object} event Event object
 * @returns {void}
 */
function storeNewCreditCard(event) {
    const creditCardAccountHelper = require('../helpers/creditCardAccount');
    const braintreeAccount = require('../../braintreeAccount');

    const $customCreditCardErrorContainer = document.getElementById('customCreditCardErrorContainer');
    const customErrorList = helper.tryParseJSON($customCreditCardErrorContainer.getAttribute('data-errors'));

    const isCheckoutPage = false;

    // Clears error messages on the top of the Account Page
    alertHandler.hideAlerts();

    try {
        if (!braintreeCreditCardModel) {
            throw ccMessages.CLIENT_MISSING_GATEWAY_CONFIGURATION;
        }

        addNewCreditCardLoader.show();

        const addCreditCardFormEl = document.querySelector('.js-braintree-add-credit-card-form');

        helper.clearForm(addCreditCardFormEl);

        hfFieldsInstance.tokenize(isCheckoutPage)
            .then(function(btModelPayload) {
                const formValidation = require('base/components/formValidation');

                const tokenizePayload = btModelPayload.btTokenizePayload;
                const addCreditCardFormUrl = addCreditCardFormEl.getAttribute('action');

                if (!helper.validateForm(addCreditCardFormEl)) {
                    addNewCreditCardLoader.hide();

                    return Promise.reject(customErrorList.VALIDATION_INVALID); // eslint-disable-line no-undef
                }

                return helper.checkForDuplicatedCC(tokenizePayload)
                    .then(function(result) {
                        if (result.error) {
                            throw result;
                        }
                    })
                    .then(function() {
                        return creditCardAccountHelper.verifyCard3dSecure(addCreditCardFormEl, braintreeCreditCardModel, tokenizePayload);
                    })
                    .then(function() {
                        // Sets value of Credit Card hidden fields needed for server side
                        creditCardAccountHelper.setCardHiddenFields(tokenizePayload);

                        // Call to Braintree-AccountAddCreditCardHandle preparation
                        const formData = new FormData(addCreditCardFormEl);
                        const $braintreeCreditCardNonce = document.getElementById('braintreeCreditCardNonce');

                        fetch(`${addCreditCardFormUrl}?braintreePaymentMethodNonce=${$braintreeCreditCardNonce.value}`, {
                            method: 'POST',
                            body: formData
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    addNewCreditCardLoader.hide();
                                    alertHandler.handleCreditCardError(data.error);
                                    delete data.error;

                                    formValidation(addCreditCardFormEl, data);
                                } else {
                                    fetch(data.renderAccountsUrl)
                                        .then((template) => template.text())
                                        .then((templateHtml) => {
                                            closeAddNewCardBlock();
                                            addNewCreditCardLoader.hide();
                                            document.querySelector('.js-credit-cards').innerHTML = templateHtml;
                                            // "Make default" link functionality reinitiation
                                            braintreeAccount.initMakeDefaultCardEvent();
                                            // "Remove Card" link functionality reinitiation
                                            braintreeAccount.initRemoveCardEvent();
                                            showCardUpdateMessage(data);
                                        });
                                }
                            });
                    });
            })
            .catch(function(error) {
                addNewCreditCardLoader.hide();
                alertHandler.handleCreditCardError(error, hfFieldsInstance.getCcFields());

                helper.validateForm(addCreditCardFormEl);
            });

        event.preventDefault();
        event.stopPropagation();
    } catch (error) {
        addNewCreditCardLoader.hide();
        alertHandler.handleCreditCardError(error);
    }
}

/**
 * Inits Credit Card account loaders
 */
function initCreditCartAccountLoaders() {
    const loaderInstance = require('../../loaderHelper');
    const $braintreeCreditCardLoader = document.getElementById('braintreeCreditCardLoader');
    const braintreeCreditCardLoaderExists = Boolean($braintreeCreditCardLoader);

    if (braintreeCreditCardLoaderExists) {
        addNewCreditCardLoader = loaderInstance($braintreeCreditCardLoader);
    }
}

/**
 * Init Credit Card Account functionality
 * @param {Object} btCreditCardModel Braintree Credit Card model
 * @param {Constructor} alertHandlerModel Alert handling model
 * @param {Object} creditCardMessages List of Credit Card error messages
 * @param {Object} hostedFieldsInstance A Hosted fields instance
 */
function init(btCreditCardModel, alertHandlerModel, creditCardMessages, hostedFieldsInstance) {
    ccMessages = creditCardMessages;
    alertHandler = alertHandlerModel;
    braintreeCreditCardModel = btCreditCardModel;
    hfFieldsInstance = hostedFieldsInstance;

    const addNewCreditCardFormExists = Boolean(document.querySelector('.js-braintree-add-credit-card-form'));

    initCreditCartAccountLoaders();

    if (addNewCreditCardFormExists) {
        document.querySelector('.js-braintree-add-new-card-save-btn').addEventListener('click', storeNewCreditCard);
        document.querySelector('.js-braintree-add-new-card-cancel-btn').addEventListener('click', closeAddNewCardBlock);
    }
}

module.exports = {
    init,
    closeAddNewCardBlock
};
