'use strict';

const base = require('base/product/base');
const helper = require('../../helper');

const PRODUCT_DETAIL_SELECTOR = '.product-detail';

/**
 * Add product to the Cart on PVP
 * @param {string} ppBtnSelector - payPal button selector
 * @param {string} flow - payPal button flow (pdp/pvp)
 * @returns {void}
 */
function addProductToCart(ppBtnSelector, flow) {
    /**
     * Gets options
     * @param {Object} productContainer - product container
     * @returns {string} options
     */
    function getOptions(productContainer) {
        return JSON.stringify(Array.from(productContainer.querySelectorAll('.product-option'))
            .map(elOption => {
                const selectedValue = elOption.querySelector('.options-select').value;
                const selectedOption = elOption.querySelector(`.options-select option[value="${selectedValue}"]`);

                return {
                    optionId: elOption.getAttribute('data-option-id'),
                    selectedValueId: selectedOption.getAttribute('data-value-id')
                };
            }));
    }

    const bundleItems = document.querySelectorAll('.bundle-item');
    const setItems = document.querySelectorAll('.set-item');

    const ppBtnContainer = document.querySelector(ppBtnSelector).closest(`.js-braintree-${flow}-button, .js-braintree-${flow}-button-global`);
    const isGlobalButton = ppBtnContainer.classList.contains(`js-braintree-${flow}-button-global`);

    const pid = base.getPidValue(ppBtnContainer);
    const form = new FormData();

    let productContainer;
    let data = {};

    if (isGlobalButton || !setItems.length) {
        productContainer = document.querySelector(ppBtnSelector).closest(PRODUCT_DETAIL_SELECTOR) || document.querySelector(PRODUCT_DETAIL_SELECTOR);
    } else {
        productContainer = document.querySelector(ppBtnSelector).closest('.set-item');
    }

    if (bundleItems.length) {
        const quantity = document.querySelector('.modal-footer, .bundle-footer').querySelector('.quantity-select').value;

        data = {
            pid: pid,
            quantity: quantity
        };

        const items = Array.from(bundleItems).map(function(item) {
            return {
                pid: item.querySelector('.product-id').innerText,
                quantity: parseInt(item.querySelector('.quantity').getAttribute('data-quantity'), 10)
            };
        });

        data.childProducts = JSON.stringify(items);
    } else if (setItems.length && isGlobalButton) {
        const items = Array.from(setItems).map(function(item) {
            return {
                pid: base.getPidValue(item),
                qty: parseInt(base.getQuantitySelected(item), 10),
                options: getOptions(item)
            };
        });

        data.pidsObj = JSON.stringify(items);
    } else {
        data = {
            pid: pid,
            quantity: base.getQuantitySelected(ppBtnContainer),
            options: getOptions(productContainer)
        };
    }

    Object.keys(data).forEach((key) => {
        form.append(key, data[key]);
    });

    return fetch(document.querySelector('.js-add-to-cart-url').value, {
        method: 'POST',
        body: form
    });
}

/**
 * Get basket data
 * @param {string} url Braintree-GetOrderInfo URL
 * @returns {Object} Object with basket data from DW
 */
function getBasketData(url) {
    return fetch(helper.getUrlWithCsrfToken(url)).then((response) => response.json());
}

/**
 * @param {string} url Validate address url
 * @param {Object} payload Payload form data
 * @returns {Promise} Promise with response result
 */
function validateAddresses(url, payload) {
    return fetch(helper.getUrlWithCsrfToken(url), {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
    }).then((response) => response.json());
}

/**
 * Submit payment (billing) data to the server
 * @param {string} submitPaymentUrl Submit payment URL
 * @param {FormData} billingFormData Billing Form Data
 * @param {string} redirectUrl Redirect URL after succesfull payment data submit
 * @param {Object} loader Loader
 * @param {Object} alertHandler Alert Handler Model instance
 * @returns {Promise} Promise with response result
 */
function submitPaymentDataToServerPromise(submitPaymentUrl, billingFormData, redirectUrl, loader, alertHandler) {
    return fetch(helper.getUrlWithCsrfToken(submitPaymentUrl), {
        method: 'POST',
        body: billingFormData
    })
        .then(function(response) {
            return response.json();
        })
        .then(function(data) {
            if (!helper.handleSubmitPaymentErrors(data, alertHandler)) {
                sessionStorage.setItem('pageState', 'cart');
                window.location.href = redirectUrl;
            }

            // loader.hide();
        })
        .catch(function(error) {
            loader.hide();

            if (error && error.redirectUrl) {
                window.location.href = error.redirectUrl;
            }
        });
}

/**
 * Removes all Gift Certificate items from the cart
 * @param {string} url
 */
async function removeGiftCertificate(url) {
    await fetch(url).then((response) => response.json());
    return;
}


module.exports = {
    addProductToCart,
    getBasketData,
    validateAddresses,
    submitPaymentDataToServerPromise,
    removeGiftCertificate
};
