'use strict';

const helper = require('../../helper');
const srcListHelper = require('./srcListHelper');
const srcButtonHelper = require('./srcButtonHelper');

/**
 * Returns Braintree SRC configs
 * @returns {Object} Braintree SRC configs
 */
function getBraintreeSRCButtonConfigs() {
    const $srcButton = document.querySelector('.js-braintree-src-button, .js-braintree-src-account-button');
    let braintreeSrcButtonConfigs = null;

    try {
        braintreeSrcButtonConfigs = helper.tryParseJSON($srcButton.getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeSrcButtonConfigs;
    }

    return braintreeSrcButtonConfigs;
}

/**
 * Returns stringified SRC shipping address
 * @param {Object} shippingData SRC shipping
 * @returns {string} stringified shipping address
 */
function createSrcShippingAddressData(shippingData) {
    const shippingAddress = {
        recipientName: `${shippingData.firstName} ${shippingData.lastName}`,
        phone: shippingData.phoneNumber,
        countryCodeAlpha2: shippingData.countryCode,
        streetAddress: shippingData.streetAddress,
        extendedAddress: shippingData.extendedAddress,
        locality: shippingData.locality,
        region: shippingData.region,
        postalCode: decodeURIComponent(shippingData.postalCode)
    };

    return JSON.stringify(shippingAddress);
}

/**
 * Returns stringified SRC billing address
 * @param {Object} billingData SRC billing
 * @param {Object} userData user data with email
 * @returns {string} stringified billing address
 */
function createSrcBillingAddress(billingData, userData) {
    const srcBillingAddress = {
        recipientName: `${billingData.firstName} ${billingData.lastName}`,
        phone: billingData.phoneNumber,
        countryCodeAlpha2: billingData.countryCode,
        streetAddress: billingData.streetAddress,
        extendedAddress: billingData.streetAddress,
        locality: billingData.locality,
        region: billingData.region,
        postalCode: decodeURIComponent(billingData.postalCode),
        email: userData.userEmail
    };

    return JSON.stringify(srcBillingAddress);
}

/**
 * Creates SRC checkout form data
 * @param {Object} srcCheckoutFormData SRC form data
 * @param {Object} srcResponseData payload from SRC
 * @param {string} pageFlow page flow
 * @returns {Object} SRC Checkout Form with appended SRC data
 */
function createSrcCheckoutFormData(srcCheckoutFormData, srcResponseData, pageFlow) {
    const $csrfToken = document.querySelector('.js-braintree-cart-buttons-wrap #csrf_token');

    const cardDescription = `${srcResponseData.details.cardType} ${srcResponseData.description}`;
    const deviceData = document.querySelector('input[name=braintreeSrcDeviceDataInput]').value;
    const shippingAddressData = createSrcShippingAddressData(srcResponseData.shippingAddress);

    srcCheckoutFormData.append($csrfToken.name, $csrfToken.value);
    srcCheckoutFormData.append('braintreeSrcNonce', srcResponseData.nonce);
    srcCheckoutFormData.append('braintreeSrcDeviceDataInput', deviceData);
    srcCheckoutFormData.append('braintreeSrcShippingAddress', shippingAddressData || '{}');
    srcCheckoutFormData.append('braintreeSrcBillingAddress', JSON.stringify(srcResponseData.billingAddress));
    srcCheckoutFormData.append('braintreeSrcCardDescription', cardDescription);
    srcCheckoutFormData.append('braintreeSrcCardType', srcResponseData.details.cardType);
    srcCheckoutFormData.append('braintreeSrcCreditCardNumber', '************' + srcResponseData.details.lastFour);

    if (pageFlow && pageFlow === window.braintreeConstants.PAGE_FLOW_CART) {
        srcCheckoutFormData.append('pageFlowCart', true);
    }

    return srcCheckoutFormData;
}

/**
 * Creates SRC form data
 * @param {Object} responseData data received after SRC tokenize
 * @param {string} paymentMethodName SRC payment method name
 * @returns {Object} SRC payment form data
 */
function createSrcFormData(responseData, paymentMethodName) {
    const srcData = {};
    const checkoutFormFields = document.querySelector('.js-braintree-cart-buttons-wrap').getAttribute('data-checkout-form-fields');

    srcData.firstName = responseData.userData.userFirstName;
    srcData.lastName = responseData.userData.userLastName;
    srcData.address1 = responseData.billingAddress.streetAddress;
    srcData.address2 = responseData.billingAddress.extendedAddress || '';
    srcData.city = responseData.billingAddress.locality;
    srcData.postalCode = decodeURIComponent(responseData.billingAddress.postalCode);
    srcData.stateCode = responseData.billingAddress.region;
    srcData.country = responseData.billingAddress.countryCode;
    srcData.email = responseData.userData.userEmail;
    srcData.phone = responseData.billingAddress.phoneNumber;
    srcData.paymentMethod = paymentMethodName;

    return helper.createPaymentFormData(checkoutFormFields, srcData);
}

/**
 * Handles all behavior related to the src content tab
 * SRC list (dropdown) change - actions for new/session account showing
 * @param {boolean} isSrcTabActive Whether Src tab is active
 */
function srcContentTabHandler(isSrcTabActive) {
    const $srcAccountList = document.getElementById('braintreeSrcAccountsList');
    const $srcSessionAccount = document.getElementById('sessionSrcAccount');
    const $srcButton = document.querySelector('.js-braintree-src-button');
    const $srcZeroAmountButton = document.querySelector('.js-braintree-billing-src-button-zero-amount');
    const $alertInfo = document.querySelector('.js-billing-address-info-alert');

    const srcOptionId = $srcAccountList.value;

    if (srcOptionId === 'newaccount') {
        $srcButton.classList.remove('d-none');
        $srcButton.classList.add('d-flex');
        $srcZeroAmountButton.style.display = 'block';
        $alertInfo.style.display = 'block';

        if (isSrcTabActive) {
            helper.continueButtonToggle(true);
        }

        srcListHelper.setIsHideContinueButtonAttr(true);
        // Case when registered user and vaulting is enabled
        srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, true);
    } else {
        $srcButton.classList.add('d-none');
        $srcButton.classList.remove('d-flex');
        $srcZeroAmountButton.style.display = 'none';
        $alertInfo.style.display = 'none';

        helper.continueButtonToggle(false);
        srcListHelper.setIsHideContinueButtonAttr(false);
        // Case when registered user and vaulting is enabled
        srcListHelper.handleSaveSrcAccountCheckbox($srcSessionAccount, helper.tryParseJSON($srcSessionAccount.getAttribute('data-save')));
    }
}

/**
 * Treats the behavior of the SRC button in the SRC tab based on the total cost
 * Shows/hides the required SRC button if the total value is/isn't 0
 * @param {DOMElement} totalAmountElement DOM Element that includes the total cost
 * @param {Object} alertHandler alertHandler model
 */
function zeroAmountSrcButtonBehavior(totalAmountElement, alertHandler) {
    const $braintreeZeroAmountSrcButton = document
        .querySelector('.js-braintree-cart-src-button-zero-amount, .js-braintree-billing-src-button-zero-amount');

    const totalAmount = Number.parseFloat(totalAmountElement.textContent.slice(1));

    // Add event listener for cart zero amount button
    if ($braintreeZeroAmountSrcButton) {
        srcButtonHelper.addZeroAmountButtonEventListener($braintreeZeroAmountSrcButton, alertHandler);
    }

    // Handles whether show or hide zero amount button
    srcButtonHelper.handleZeroAmountButtonVisibility(totalAmount);

    // Creates a mutation obserber for total amount element
    srcButtonHelper.initTotalAmountElementObserver(totalAmountElement);
}

module.exports = {
    getBraintreeSRCButtonConfigs,
    createSrcShippingAddressData,
    createSrcBillingAddress,
    createSrcFormData,
    srcContentTabHandler,
    createSrcCheckoutFormData,
    zeroAmountSrcButtonBehavior
};
