'use strict';

var helper = require('../../helper');

/**
 * General validation of params needed for initialization of hosted fields
 * @param {Object} $braintreeCreditCardFieldsContainer Braintree Credit Card fields container
 * @param {Object} braintreeCreditCardConfig Braintree Credit Card configurations (they came from server via data attr)
 * @returns {Object} object with "error" boolean flag and array of error messages from "massages" attr
 */
function generalHfValidation($braintreeCreditCardFieldsContainer, braintreeCreditCardConfig) {
    const response = {
        error: false,
        messages: ''
    };

    try {
        const isHostedFieldsWasInited = helper.tryParseJSON($braintreeCreditCardFieldsContainer.getAttribute('data-is-inited'));
        const isBraintreeCreditCardConfigValid = typeof braintreeCreditCardConfig !== 'object' || braintreeCreditCardConfig !== null;

        if (isHostedFieldsWasInited) {
            // do not add error msg since this case is expected (hosted fields should be inited just once)
            response.error = true;
        }

        if (!isBraintreeCreditCardConfigValid) {
            response.error = true;
            response.messages = '.js-braintree-credit-card-fields has not valid data-braintree-config';
        }
    } catch (error) {
        response.error = true;
        response.messages = error;
    }

    return response;
}

/**
 * Init Credit Card fields functionality
 * @param {Object} btCreditCardModel Braintree Credit Card model
 * @param {Constructor} alertHandlerModel Alert handling model
 * @param {Object} hostedFieldsInstance A Hosted fields instance
 */
function init(btCreditCardModel, alertHandlerModel, hostedFieldsInstance) {
    alertHandlerModel.hideAlerts();
    var loader = $('#braintreeCreditCardLoader');
    document.querySelectorAll('.js-braintree-credit-card-fields').forEach(function(el) {
        try {
            const $braintreeCreditCardFieldsContainer = el;
            const braintreeCreditCardConfig = helper.tryParseJSON($braintreeCreditCardFieldsContainer.getAttribute('data-braintree-config'));
            const generalHostedFieldsValidation = generalHfValidation($braintreeCreditCardFieldsContainer, braintreeCreditCardConfig);

            if (generalHostedFieldsValidation.error && generalHostedFieldsValidation.messages.length) {
                throw generalHostedFieldsValidation.messages;
            }
            loader.show();
            // Fraud Data will be only collected in case if corresponding Site Preference is enabled
            // For more details go inside "btCreditCardModel.collectFraudData" function logic
            btCreditCardModel.collectFraudData()
                .then(function(fraudDataPayload) {
                    // const $braintreeDeviceData = document.getElementById('braintreeDeviceData');

                    if (fraudDataPayload.deviceData) {
                        // $braintreeDeviceData.value = fraudDataPayload.deviceData;
                        $('input[name="braintreeDeviceData"]').val(fraudDataPayload.deviceData);
                    }
                    loader.hide();
                    // Init "Credit Card Hosted Fields"
                    return hostedFieldsInstance.initHostedFields();
                })
                .then(function(hostedFieldsInstance) {
                    // Customization - Attach 'cardTypeChange' listener after hosted fields are initialized
                    hostedFieldsInstance.on('cardTypeChange', (event) => {
                        if (event.cards.length === 1) {
                            const cardType = event.cards[0].type;
                            $('.card-image').attr('class', `card-image ${cardType}`);
                        } else {
                            $('.card-image').attr('class', 'card-image');
                        }
                    });

                    hostedFieldsInstance.on('blur', function (event) {
                        var field = event.fields[event.emittedBy];
                        if (!field.isValid) {
                            $(field.container).addClass('braintree-hosted-fields-invalid')
                        } else {
                            $(field.container).removeClass('braintree-hosted-fields-invalid')
                        }
                    });
                })
                .catch(function(error) {
                    loader.hide();
                    alertHandlerModel.handleCreditCardError(error);
                });
            // Update order data inside BT model each time when buyer update checkout view
            $('body').on('checkout:updateCheckoutView', btCreditCardModel.updateOrderData);

            // To prevent from repeatable Credit Card hosted fields initialization
            $braintreeCreditCardFieldsContainer.setAttribute('data-is-inited', true);
        } catch (error) {
            loader.hide();
            alertHandlerModel.handleCreditCardError(error);
        }
    });
}

function addNewCardModalTriggerInputID() {
    const addCardModal = '#addCardModal';
    const savedCard = '#dwfrm_billing';
    var inputIDs = ['braintreeCardBillingAddress', 'braintreeCardType', 'braintreeCardMaskNumber', 'braintreeCardExpirationMonth', 'braintreeCardExpirationYear', 'braintreeCardHolder', 'braintreeCreditCardNonce', 'braintreeIs3dSecureRequired', 'braintreeCreditCardBin', 'braintreeCvvOnlyNonce', 'braintreeCreditCardErrorContainer', 'customCreditCardErrorContainer', 'braintreeCreditCardLoader', 'cardOwnerName'];
    var inputNames = ['dwfrm_billing_creditCardFields_cardNumber', 'braintreePaymentMethodNonce'];
    $(addCardModal).on('show.bs.modal', function (event) {
        $.each(inputIDs, function(index, value) {
            $(`${addCardModal} #${value}-inactive`).attr('id',`${value}`);
            $(`${savedCard} #${value}`).attr('id',`${value}-inactive`);
        });
        $.each(inputNames, function(index, value) {
            $(`${addCardModal} input[name=${value}-inactive]`).attr('name',`${value}`);
            $(`${savedCard} input[name=${value}]`).attr('name',`${value}-inactive`);
        });
    });
    $(addCardModal).on('hide.bs.modal', function (event) {
        $.each(inputIDs, function(index, value) {
            $(`${addCardModal} #${value}`).attr('id',`${value}-inactive`);
            $(`${savedCard} #${value}-inactive`).attr('id',`${value}`);
        });
        $.each(inputNames, function(index, value) {
            $(`${addCardModal} input[name=${value}]`).attr('name',`${value}-inactive`);
            $(`${savedCard} input[name=${value}-inactive]`).attr('name',`${value}`);
        });
    });
}

module.exports = {
    init,
    addNewCardModalTriggerInputID
};
