'use strict';

const braintreeCreditCardSDK = require('base_braintree/braintree/creditcard/braintreesdk/braintreeCreditCardSDK');
const braintreeGeneral = require('base_braintree/braintree/braintreeGeneral');
const helper = require('../../helper');

/* global Promise braintree $ */

// global variables
let btClientInstancePromise;
let orderData;
let sitePrefs = {};

/**
 * Init Braintree Credit Card model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeCreditCardSDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    sitePrefs = {
        isFraudToolsEnabled: isFraudToolsEnabled
    };
}

/**
 * Gets required additional shipping info for 3ds
 *
 * @param {Object} orderAddress - User's shipping address
 * @returns {Object} an object with required fields
 */
function getShippingAdditionalInfo(orderAddress) {
    return {
        workPhoneNumber: orderAddress.phone,
        shippingGivenName: orderAddress.recipientName.split(' ').slice(0, -1).join(' '),
        shippingSurname: orderAddress.recipientName.split(' ').slice(-1).join(' '),
        shippingPhone: orderAddress.phone,
        shippingAddress: {
            streetAddress: orderAddress.line1,
            extendedAddress: orderAddress.line2,
            locality: orderAddress.city,
            region: orderAddress.state,
            postalCode: decodeURIComponent(orderAddress.postalCode),
            countryCodeAlpha2: orderAddress.countryCode
        }
    };
}

/**
 * Updates Order data on Checkout
 */
function updateOrderData() {
    fetch(helper.getUrlWithCsrfToken(`${window.braintreeUrls.getOrderInfoUrl}?qwe=2344`))
        .then((response) => {
            if (!response.ok) {
                window.location.reload();
            }

            return response.json();
        })
        .then((data) => {
            data.shippingAdditionalInfo = data.shippingAddress ? getShippingAdditionalInfo(data.shippingAddress) : null;
            orderData = data;
        });
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    let response;

    if (sitePrefs && sitePrefs.isFraudToolsEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: 'Fraud Data collection isn\t enabled via Custom Site Preference'
        });
    }

    return response;
}

/**
 * @param {Object} tokenizationOptions Tokenize options
 */
function addAuthenticationInsight(tokenizationOptions) {
    const braintreeCreditCardConfigs = require('../helpers/creditCardHelper').getCheckoutBraintreeCreditCardConfigs();

    if (braintreeCreditCardConfigs) {
        tokenizationOptions.authenticationInsight = {
            merchantAccountId: braintreeCreditCardConfigs.merchantAccountId
        };
    }
}

/**
 * @param {string} nonce payment nonce value
 * @returns {Object} regulation environment value of false if an error occurs
 */
function getAuthInsightRegulationEnvironment(nonce) {
    const config = require('../helpers/creditCardHelper').getCheckoutBraintreeCreditCardConfigs();
    const token = document.querySelector('#dwfrm_billing [name="csrf_token"]').value;

    return fetch(config.vaultCreditCardUrl, {
        method: 'POST',
        body: `nonce=${nonce}&csrf_token=${token}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(response => response.json());
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {Object} verifyCardObject Object with Credit Card info for verification
 * @param {Object} btTokenizePayload Tokenize payload
 * @param {sring} threeDSecureFlow 3DSecure flow value
 * @returns {Object} Object with nonce payload or custom error
 */
function apply3dSecureValidation(verifyCardObject, btTokenizePayload, threeDSecureFlow) {
    return braintreeCreditCardSDK.apply3dSecureValidation(verifyCardObject, threeDSecureFlow)
        .then(function(payload) {
            if (btTokenizePayload) {
                // For stored card use case we don't need this param
                btTokenizePayload.nonce = payload.nonce;
            }

            return {
                threeDSecureDataValidationPayload: payload,
                btTokenizePayload: btTokenizePayload
            };
        });
}

/**
 * @param {string} customPreference Custom preference value
 * @param {Object} payload Payload
 * @returns {boolean} 3DSecure flow or not
 */
function is3DSecureFlow(customPreference, payload) {
    if (['always', 'always_challengeRequested'].includes(customPreference)) {
        return true;
    }

    if (customPreference === 'auto') {
        const regulationEnvironment = helper.getValueByKey(payload, 'authenticationInsight.regulationEnvironment', '');

        return ['unavailable', 'psd2', 'psdtwo'].includes(regulationEnvironment);
    }

    return false;
}

/**
 * Process the new Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {boolean} isCheckoutPage Is checkout flow flag
 * @param {HostedFields} hfInstance A hosted fields instance
 * @returns {Object} Object with nonce payload or custom error
 */
function processNewCard(email, billingData, isCheckoutPage, hfInstance) {
    const orderAmount = orderData.amount;
    const shippingAdditionalInfo = orderData.shippingAdditionalInfo;

    let result = hfInstance.tokenize(isCheckoutPage);

    if (['auto', 'always', 'always_challengeRequested'].includes(window.braintreePreferences.cc3DSecureFlow)) {
        const resultTokenize = result;

        result = result
            .then(function(payload) {
                const nonce = payload.btTokenizePayload.nonce;
                const bin = payload.btTokenizePayload.details ? payload.btTokenizePayload.details.bin : '';

                if (window.braintreePreferences.cc3DSecureFlow === 'auto'
                    && !is3DSecureFlow(window.braintreePreferences.cc3DSecureFlow, payload.btTokenizePayload)) {
                    return resultTokenize;
                }

                const verifyCardObject = {
                    amount: orderAmount,
                    nonce: nonce,
                    bin: bin || '',
                    email: email,
                    billingAddress: billingData,
                    additionalInformation: shippingAdditionalInfo
                };

                return apply3dSecureValidation(verifyCardObject,
                    payload.btTokenizePayload,
                    window.braintreePreferences.cc3DSecureFlow);
            });
    }

    return result;
}

/**
 * Process the stored Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {Object} paymentMethodResponse Payment method resonse
 * @returns {Object} Object with nonce payload or custom error
 */
function processStoredCard(email, billingData, paymentMethodResponse) {
    const nonce = paymentMethodResponse.nonce;
    const bin = paymentMethodResponse.details ? paymentMethodResponse.details.bin : '';

    const orderAmount = orderData.amount;
    const shippingAdditionalInfo = orderData.shippingAdditionalInfo;

    let result = Promise.resolve({
        nonce,
        email,
        bin
    });

    // Case when we have a nonce, it is the case when buyer use stored credit card
    // otherwise, it is the case when buyer use new credit card
    if (is3DSecureFlow(window.braintreePreferences.cc3DSecureFlow, paymentMethodResponse)) {
        const verifyCardObject = {
            nonce: nonce,
            amount: orderAmount,
            email: email,
            bin: bin || '',
            billingAddress: billingData,
            additionalInformation: shippingAdditionalInfo
        };

        result = apply3dSecureValidation(verifyCardObject, undefined, window.braintreePreferences.cc3DSecureFlow);
    }

    return result;
}

/**
 * In case of session card we can do nothing since the nonce, 3ds, hosted fields validation already was passed
 * @returns {Promise} return Promise with success data
 */
async function processSessionCard() {
    const saveCreditCardCheckboxEl = document.getElementById('braintreeSaveCreditCard');

    if (saveCreditCardCheckboxEl?.checked) {
        const ccSessionAccountEl = document.getElementById('braintreeSessionCreditAccount');
        const expiryDate = ccSessionAccountEl.dataset.expiration.split('/');
        const expiryYear = expiryDate[1].length === 2 ? expiryDate[1].replace(/^/, '20') : expiryDate[1];
        const sessionCardData = {
            details: {
                lastFour: ccSessionAccountEl.dataset.lastFour,
                expirationMonth: expiryDate[0],
                expirationYear: expiryYear,
                cardType: ccSessionAccountEl.dataset.type
            }
        };

        const response = await helper.checkForDuplicatedCC(sessionCardData);

        if (response.error) {
            return Promise.reject(response.message);
        }
    }

    return Promise.resolve({});
}

/**
 * Checks whether the basket is valid
 * @param {string} errorMessage Error message
 * @returns {Object} response object
 */
function isBasketDataValid(errorMessage) {
    const response = {
        error: false,
        customErrorMessage: ''
    };

    if (!orderData) {
        response.error = true;
        response.customErrorMessage = errorMessage;
    }

    return response;
}

/**
 * Get BT client instance which is used currently in the model
 * @returns {Promise} BT client instance promise
 */
function getClientInstancePromise() {
    return btClientInstancePromise;
}

module.exports = {
    init,
    // "tokenize" or "processNewCard", or "processStoredCard"
    // can be called only after excution of "initCreditCardFields"
    processNewCard,
    processStoredCard,
    processSessionCard,
    getShippingAdditionalInfo,
    getClientInstancePromise,
    getAuthInsightRegulationEnvironment,
    addAuthenticationInsight,
    // "isBasketDataValid" can be called only after calling of "updateOrderData"
    isBasketDataValid,
    updateOrderData,
    collectFraudData,
    is3DSecureFlow
};
