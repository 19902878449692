'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const payPalExpressModelHelper = require('base_braintree/braintree/paypal/helpers/payPalExpressModelHelper');
const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');
const requestHelper = require('../helpers/payPalRequestsHelperGlobal');
const helper = require('../../helper');
// const loaderInstance = require('base_braintree/braintree/loaderHelper');

/**
 * PayPal express model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalExpressModel(alertHandlerModel,
                            btClientInstancePromise,
                            payPalButtonSelector,
                            payPalConfigurations,
                            $csrfToken,
                            $loaderContainer) {
    // Init PayPalBaseModel constructor
    PayPalBaseModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $loaderContainer);

    this.$csrfToken = $csrfToken;
    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
    this.loader = $('.loader-preventive');
}

// ES5 inheritance
PayPalExpressModel.prototype = Object.create(PayPalBaseModel.prototype);

// For more details see same method from "PayPalBaseModel"
// Add additional configs which are needed for "Change payment method button" feature
PayPalExpressModel.prototype.payPalCheckoutInstanceConfigs = function(btClientInstance) {
    const checkoutInstanceConfigs = PayPalBaseModel.prototype.payPalCheckoutInstanceConfigs.call(this, btClientInstance);

    if (this.changePMButtonEnabled) {
        checkoutInstanceConfigs.autoSetDataUserIdToken = true;
    }

    return checkoutInstanceConfigs;
};

/**
 * Method triggers when buyer clicked on PayPal button and PayPal modal is opening
 * @returns {Object} Amount and Shipping address from basket
 */
PayPalExpressModel.prototype.onOrderCreateCallback = async function() {
    const basketDataUrl = this.basketDataUrl;

    try {
        if (this.removeGiftCertificateURL) {
            await requestHelper.removeGiftCertificate(this.removeGiftCertificateURL);
        }
    } catch (error) {
        console.error("Error removing gift certificate:", error);
    }

    this.basketData = await requestHelper.getBasketData(basketDataUrl);

    const isUseSavedPaypalAddress = this.changePMButtonEnabled && !this.basketData.shippingAddress;

    if (isUseSavedPaypalAddress) {
        this.savedPaypalAddress = payPalExpressModelHelper.getSavedPaypalAddress(this.payPalButtonSelector);
    }

    return PayPalBaseModel.prototype.onOrderCreateCallback.call(this);
};

// For more details see same method from "PayPalBaseModel"
// Create and submit billing data to the server. Redirect on order reivew checkout step
PayPalExpressModel.prototype.onApprovePaymentCallback = function(payload) {
    // Calling parrent method to proceed with PayPal payload validation
    PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload, true);

    // Error will be handled on BaseModel level
    if (this.error) {
        return;
    }

    this.loader.show();

    const PayPalExpressInstance = this;
    const payloadDetails = payload.details;
    const $payPalButtonWrapper = document.querySelector('.js-braintree-cart-paypal-buttons-wrap');
    const $braintreePayPalFundingSource = document.getElementById('braintreePaypalFundingSource');
    const braintreePayPalFundingSource = $braintreePayPalFundingSource.value;

    const paypalCheckoutFormFields = helper.tryParseJSON($payPalButtonWrapper.getAttribute('data-paypal-checkout-form-fields'));
    payloadDetails.billingAddress = payloadDetails.shippingAddress;
    const billingAddressData = billingFormHelper.createBillingAddressData(payloadDetails);
    try {
        if((typeof billingAddressData.phone === "undefined") || !billingAddressData.phone) {
            billingAddressData.phone = '0000000000';
        }

        if((typeof payloadDetails.shippingAddress.phone === "undefined") || !payloadDetails.shippingAddress.phone) {
            payloadDetails.shippingAddress.phone = '0000000000';
        }
    } catch (err) {
        console.log('phone number not valid');
    }

    // Create form data only with billing address
    const billingFormData = billingFormHelper.createBillingAddressFormData(paypalCheckoutFormFields, billingAddressData);

    billingFormHelper.appendCsrfTokenToFormData(billingFormData, PayPalExpressInstance.$csrfToken);
    billingFormHelper.appendBraintreePaypalFundingSourceToFormData(billingFormData, braintreePayPalFundingSource);

    //mParticle Event trigger
    if(window.mParticleCheckoutInitiated) {
        var page = window.location.href;
        window.mParticleCheckoutInitiated('paypal', page);
    }

    // REGEX for js-braintree-(pdp|pvp)-button, js-braintree-(pdp|pvp)-button-global
    // const BRAINTREE_BUTTON_REGEX = /(^|\s)js-braintree-p[dv]p-button(-global)?(\s|$)/;

    // It will generate FFraud data only if it Fraud Data collection was enabled
    PayPalExpressInstance.fraudDataPromise
        .then(function(data) {
            if (!data.fraudDataCollectionDisabled) {
                billingFormHelper.appendFraudDataToFormData(billingFormData, data.deviceData);
            }
        }).then(function() {

            let shippingAddressData;

            // If shipping address was presented in PayPal payload, create a shipping address object,
            // stringify it and store in FormData as an input
            if (payloadDetails.shippingAddress) {
                shippingAddressData = payPalExpressModelHelper.createShippingAddressData(payloadDetails);

                payPalExpressModelHelper.appendShippingDataAsStringToFormData(billingFormData, shippingAddressData);
            }

            // Create a shipping address object, stringify it and store in FormData as an input
            billingFormHelper.appendBillingAddressAsStringToFormData(billingFormData, billingAddressData);

            billingFormHelper.appendEmailAsStringToFormData(billingFormData, payloadDetails.email);

            billingFormHelper.appendBtPaymentFieldsToFormData(billingFormData, payload);

        }).then(function() {
            // Submit customer form with email (CheckoutServices-SubmitCustomer)
            // as we skip step "login via guest/registered user" while express checkout
            // email is set only in case of guest checkout and if email is not already set
            payPalExpressModelHelper.submitCustomerForm(payloadDetails.email);
        })
        .then(function() {
            requestHelper.submitPaymentDataToServerPromise(PayPalExpressInstance.submitPaymentUrl,
                billingFormData,
                PayPalExpressInstance.placeOrderStageUrl,
                PayPalExpressInstance.loader,
                PayPalExpressInstance.alertHandlerModel);
            PayPalExpressInstance.loader.show();
        })
        .catch(function(error) {
            PayPalExpressInstance.alertHandlerModel.showError(error);

            PayPalExpressInstance.loader.hide();
        });
};

module.exports = PayPalExpressModel;
