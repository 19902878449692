'use strict';

/* global Promise braintree $ */

const applePayErrorMessages = require('../constants/errorMessages');

const braintreeApplePaySDK = require('./braintreeApplePaySDK');
const braintreeGeneral = require('../../braintreeGeneral');
const helper = require('../../helper');

// Global variables
// Is Froud tools enabled
let isFtEnabled;
let btClientInstancePromise;
let apInstance;
let amount;

/**
 * Init braintree Apple Pay model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeApplePaySDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    isFtEnabled = isFraudToolsEnabled;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    let response;

    if (isFtEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: applePayErrorMessages.FRAUD_DATA_CELLECTION_ISNOT_ENABLED
        });
    }

    return response;
}

/**
 * Preparing Apple Pay payment
 * @param {Object} ApplePaySession Apple Pay session
 * @returns {Promise} Promise with 'canMakePayments' boolean value
 */
function initApplePay(ApplePaySession) {
    return braintreeApplePaySDK.createApplePayPayment()
        .then(function(applePayInstance) {
            apInstance = applePayInstance;

            return ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
        });
}

/**
 * Merges a payment request with Braintree defaults to return an {external:ApplePayPaymentRequest}
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Object} Payment request object
 */
function createPaymentDataRequest(applePayConfigs) {
    return braintreeApplePaySDK.createPaymentDataRequest(apInstance, applePayConfigs, amount);
}

/**
 * Validates merchant website, as required by ApplePaySession before payment can be authorized
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Promise} Promise with merchant session
 */
function onValidateMerchant(sessionEvent, applePayConfigs) {
    return braintreeApplePaySDK.performValidation(apInstance, sessionEvent, applePayConfigs);
}

/**
 * Tokenizes an Apple Pay payment
 * @param {Object} sessionEvent Apple Pay session event
 * @returns {Promise} Promise with tokenized payload
 */
function onPaymentAuthorized(sessionEvent) {
    return braintreeApplePaySDK.tokenize(apInstance, sessionEvent);
}

/**
 * Adds shipping methods based on the address
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Promise} Promise with applicable shipping options
 */
async function onShippingContactSelected(sessionEvent, applePayConfigs) {
    const shippingAddress = sessionEvent.shippingContact;
    const city = shippingAddress.locality;
    const stateCode = shippingAddress.administrativeArea;
    const countryCode = shippingAddress.countryCode;
    const postalCode = shippingAddress.postalCode;
    const addressQueryString = `city=${city}&stateCode=${stateCode}&countryCode=${countryCode}&postalCode=${postalCode}`;
    const paymentMethodId = window.braintreeConstants.PAYMENT_METHOD_ID_APPLEPAY;
    const url = `${window.braintreeUrls.getApplicableShippingOptions}?paymentMethodId=${paymentMethodId}&${addressQueryString}`;

    const shippingOptions = await helper.getApplicableShippingOptions(url);

    return {
        newTotal: {
            label: applePayConfigs.options.displayName,
            amount: amount
        },
        newShippingMethods: shippingOptions
    };
}

/**
 * Updates price based on a selected shipping method
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Promise} Promise with new order amount
 */
async function onShippingMethodSelected(sessionEvent, applePayConfigs) {
    const newShippingMethod = sessionEvent.shippingMethod;
    const shippingOptionId = newShippingMethod.identifier;

    const updatedAmount = await helper.updateAmountForShippingOption(shippingOptionId);

    return {
        newTotal: {
            label: applePayConfigs.options.displayName,
            amount: updatedAmount
        }
    };
}

/**
 * Update order amount
 * @param {Object} applePayConfigs Apple Pay config object
 */
function updateApplePayAmount(applePayConfigs) {
    // Need to use the ajax approach here because of Apple Pay session limitations
    $.ajax({
        url: helper.getUrlWithCsrfToken(applePayConfigs.getOrderInfoUrl),
        async: false,
        type: 'get',
        dataType: 'json',
        success: function(data) {
            amount = data.amount;
        },
        error: function() {
            window.location.reload();
        }
    });
}

module.exports = {
    init,
    collectFraudData,
    initApplePay,
    createPaymentDataRequest,
    onValidateMerchant,
    onPaymentAuthorized,
    updateApplePayAmount,
    onShippingContactSelected,
    onShippingMethodSelected
};
