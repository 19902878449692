'use strict';

const helper = require('../../helper');

/**
 * Sets value of credit card hidden fields needed for server side
 * @param {Object} tokenizePayload Tokenize payload
 */
function setCardHiddenFields(tokenizePayload) {
    const tokenizePayloadDetails = tokenizePayload.details;
    const $braintreeCreditCardMakeDefault = document.getElementById('braintreeCreditCardMakeDefault');

    document.getElementById('braintreeCreditCardNonce').value = tokenizePayload.nonce;
    document.getElementById('braintreeCardType').value = tokenizePayloadDetails.cardType;
    document.getElementById('braintreeCardMaskNumber').value = tokenizePayloadDetails.lastFour;
    document.getElementById('braintreeCardHolder').value = tokenizePayloadDetails.cardholderName;
    document.getElementById('cardOwnerName').value = tokenizePayloadDetails.cardholderName;
    document.querySelector('input[name=dwfrm_creditCard_cardNumber]').value = tokenizePayloadDetails.lastFour;

    if($braintreeCreditCardMakeDefault) {
        document.getElementById('braintreeDefaultCard').value = $braintreeCreditCardMakeDefault.checked;
    }
}

/**
 * @param {Object} billingAddress billing address
 * @returns {Object} preperad data
 */
function getDataForVerifyCard(billingAddress) {
    let preparedData = {};

    const $customerInfo = document.querySelector('.js-braintree-add-new-card-block');

    if ($customerInfo) {
        preparedData = helper.tryParseJSON($customerInfo.getAttribute('data-customer-info-card'));
    }

    if (Object.keys(preparedData.billingData).length === Object.keys(billingAddress).length) {
        preparedData.billingData = billingAddress;
    }

    return preparedData;
}

/**
 * @param {HTMLFormElement} form - HTML form element
 * @param {Object} braintreeCreditCardModel credit card model
 * @param {Object} tokenizePayload payload
 * @returns {Object} result of validation
 */
function verifyCard3dSecure(form, braintreeCreditCardModel, tokenizePayload) {
    if (braintreeCreditCardModel.is3DSecureFlow(window.braintreePreferences.cc3DSecureAccountPage, tokenizePayload)) {
        const orderAmount = '0.00';
        const billingData = helper.getFormFieldsData(form, 'dwfrm_address_');
        const customerInfo = getDataForVerifyCard(billingData);

        const nonce = tokenizePayload.nonce;
        const bin = tokenizePayload.details ? tokenizePayload.details.bin : '';

        const verifyCardObject = {
            amount: orderAmount,
            nonce: nonce,
            bin: bin || '',
            email: customerInfo.email,
            billingAddress: customerInfo.billingData,
            additionalInformation: customerInfo.shippingAdditionalInfo
        };

        const braintreeCreditCardSDK = require('../braintreesdk/braintreeCreditCardSDK');

        return braintreeCreditCardSDK.apply3dSecureValidation(verifyCardObject, window.braintreePreferences.cc3DSecureAccountPage)
            .then(function(payload) {
                if (tokenizePayload) {
                    // For stored card use case we don't need this param
                    tokenizePayload.nonce = payload.nonce;
                }

                return {
                    threeDSecureDataValidationPayload: payload,
                    btTokenizePayload: tokenizePayload
                };
            });
    }

    return {};
}

module.exports = {
    verifyCard3dSecure,
    setCardHiddenFields,
    getDataForVerifyCard
};
