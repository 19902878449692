'use strict';

const payPalStoredBillingAddressComponent = require('./payPalStoredBillingAddress');
const {
    isVaultModeEnabled,
    getAccountType,
    setNonceInputValue,
    hideSavePayPalAccountBlock,
    displaySavePayPalAccountBlock,
    displayPayPalButton,
    hidePayPalButon,
    displayContinueButton,
    hideContinueButon,
    isBuyerAuthenticated,
    selectPayPalCheckoutTab,
    setBraintreePayPalEmailInput,
    handleStoredAccountsShowing
} = require('../helpers/payPalListHelper');

const paymentMethodGeneral = require('../../paymentMethodGeneral');

let $payPalAccountList;
let $continueButton;
let $savePaypalAccountCountainer;
let $braintreePayPalNonce;
let $paypalButton;
let $payPalTab;
let $braintreePaypalEmail;

const constantList = {
    NEW_ACCOUNT: 'new_account',
    STORED_ACCOUNT: 'stored_account'
};

/**
 * The function which manages what should be done when stored/new account was selected from the account dropdown
 * @param {Promise} fraudDataPromise A promise received as a result of generating fraud data
 */
async function accountsListChange(fraudDataPromise) {
    const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');

    const accountType = getAccountType($payPalAccountList, constantList);
    const buyerAuthentificated = isBuyerAuthenticated($payPalAccountList);
    const vaultModeEnabled = isVaultModeEnabled($paypalButton);
    const $selectedPayPalAccountOption = $payPalAccountList.options[$payPalAccountList.selectedIndex];

    // eslint-disable-next-line default-case
    switch (accountType) {
        // Buyer selected new PayPal account
        case constantList.NEW_ACCOUNT:
            displayPayPalButton($paypalButton);
            hideContinueButon($continueButton);

            if (vaultModeEnabled && buyerAuthentificated) {
                displaySavePayPalAccountBlock($savePaypalAccountCountainer);
            }

            payPalStoredBillingAddressComponent.displaySelectedStoredBillingAddress($selectedPayPalAccountOption);

            break;

        // Buyer selected stored account
        case constantList.STORED_ACCOUNT:
            const selectedAccountEmail = $selectedPayPalAccountOption.text;

            if (vaultModeEnabled) {
                hideSavePayPalAccountBlock($savePaypalAccountCountainer);
            }

            setNonceInputValue($braintreePayPalNonce, '');
            displayContinueButton($continueButton);
            hidePayPalButon($paypalButton);

            selectPayPalCheckoutTab($payPalTab);
            setBraintreePayPalEmailInput($braintreePaypalEmail, selectedAccountEmail);

            // Display billing address of selected PayPal account
            payPalStoredBillingAddressComponent.displaySelectedStoredBillingAddress($selectedPayPalAccountOption);

            // Set Device data
            const fraudData = await fraudDataPromise;

            if (!fraudData.fraudDataCollectionDisabled) {
                billingFormHelper.fillFraudDataInput(fraudData);
            }

            break;
    }
}

/**
 * Init PayPal Account list functionality
 * @param {Promise} fraudDataPromise A promise received as a result of generating fraud data
 */
function init(fraudDataPromise) {
    $payPalAccountList = document.getElementById('braintreePaypalAccountsList');
    $savePaypalAccountCountainer = document.getElementById('braintreeSavePaypalAccountContainer');
    $braintreePayPalNonce = document.getElementById('braintreePayPalNonce');
    $paypalButton = document.querySelector('.js-braintree-paypal-billing-button');
    $continueButton = document.querySelector('button.submit-payment');
    $payPalTab = document.querySelector('.js-paypal-tab');
    $braintreePaypalEmail = document.getElementById('braintreePaypalEmail');

    const buyerAuthenticated = isBuyerAuthenticated($payPalAccountList);

    // Add on change "account list" event
    $payPalAccountList.addEventListener('change', () => accountsListChange(fraudDataPromise));
    // Saves selected billing address from CREDIT_CARD tab
    paymentMethodGeneral.saveSelectedBillingAddressFromPreviousTab();
    // Execute "account list" change function to setup init values
    accountsListChange(fraudDataPromise);
    // Check whether buyer has proceeded with the same email which is already stored
    // if yes we hide stored account with the same email in dropdown
    // if no we show all existent PP accounts in dropdown
    handleStoredAccountsShowing(buyerAuthenticated);
}

module.exports = {
    init
};
