'use strict';
//const paymentMethodGeneral = require("./paymentMethodGeneral");

/**
 * Inits credit card functionality on Storefront
 * @returns {void}
 */
function initCreditCardFunctionality() {
    const creditCardOnAccountEnabled = Boolean(document.querySelector('.js-braintree-add-credit-card-form'));
    const creditCardEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_creditCardContent'));

    let creditCardBusinessLogic;

    if (creditCardOnAccountEnabled || creditCardEnabledOnCheckout) {
        creditCardBusinessLogic = require('./creditcard/creditCard');
        creditCardBusinessLogic.init();
    }

    if (creditCardOnAccountEnabled) {
        creditCardBusinessLogic.initAccount();
    }

    if (creditCardEnabledOnCheckout) {
        creditCardBusinessLogic.initCheckout();
    }
}

// /**
//  * Inits PayPal functionality on Storefront
//  * @param {Promise} clientTokenPromise promise with Braintree Client Instance
//  * @returns {void}
//  */
async function initPayPalFunctionality(clientTokenPromise) {
    const payPalBusinessLogic = require('./paypal/payPal');

    const payPalEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-paypal-cart-button'));
    const payPalEnabledOnProduct = Boolean(document.querySelector('.prices-add-to-cart-actions .js-braintree-paypal-cart-button'));
    const payPalEnabledOnPvp = Boolean(document.querySelector('#quickViewModal .js-braintree-paypal-cart-button'));
    const payPalEnabledOnCart = Boolean(document.querySelector('.js-braintree-paypal-cart-button'));
    const payPalEnabledOnAccountPage = Boolean(document.querySelector('.js-paypal-accounts'));
    const payPalEnabledOnLoginPage = Boolean(document.querySelector('.login-form-nav'));
    const cwppEnabledOnPage = Boolean(document.getElementById('js-cwpp-button'));

    payPalBusinessLogic.init(clientTokenPromise);

    const availablePages = [
        payPalEnabledOnProduct, payPalEnabledOnCart,
        payPalEnabledOnCheckout, payPalEnabledOnLoginPage,
        payPalEnabledOnAccountPage, cwppEnabledOnPage,
        payPalEnabledOnPvp
    ];

    if (availablePages.includes(true)) {
        const PayPalScriptLoading = require('base_braintree/braintree/paypal/models/payPalScriptLoading');
        const cwpp = require('base_braintree/braintree/paypal/components/connectWithPayPal.js');

        const payPalScriptLoading = new PayPalScriptLoading();

        if (cwpp.isContainerExist()) {
            await payPalScriptLoading.loadPayPalSDK();

            payPalScriptLoading.loadConnectWithPayPal(cwpp.init);
        }
    }
    if (payPalEnabledOnCart) {
        payPalBusinessLogic.initCart();
    }
}

/**
 * Inits GooglePay functionality on Storefront
 * @returns {void}
 */
function initGooglePayFunctionality() {
    const googlePayBusinnessLogic = require('base_braintree/braintree/googlepay/googlePay');

    const googlePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-billing-googlepay-button'));
    const googlePayEnabledOnCart = Boolean(document.querySelector('.js-braintree-cart-googlepay-button'));

    googlePayBusinnessLogic.init();

    if (googlePayEnabledOnCheckout) {
        googlePayBusinnessLogic.initCheckout();
    }

    if (googlePayEnabledOnCart) {
        googlePayBusinnessLogic.initCart();
    }
}

/**
 * Inits ApplePay functionality on Storefront
 * @returns {void}
 */
function initApplePayFunctionality() {
    const applePayBusinessLogic = require('./applepay/applePay');

    const applePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-applepay-content'));
    const applePayEnabledOnCart = Boolean(document.querySelector('.js-braintree-applepay-button'));
    const $appleButtonWrap = document.querySelector('.js_braintree_applepayButtonsWrap');
    if (window.ApplePaySession) {
        if ($appleButtonWrap) {
            $appleButtonWrap.classList.add('d-block');
        }
    }
    applePayBusinessLogic.init();

    if (applePayEnabledOnCart || applePayEnabledOnCheckout) {
        applePayBusinessLogic.initApplePay();
    }
}

/**
 * Inits Venmo functionality on Storefront
 * @returns {void}
 */
function initVenmoFunctionality() {
    const venmoBusinessLogic = require('base_braintree/braintree/venmo/venmo');

    const isVenmoEnabledOnAccountPage = Boolean(document.querySelector('.js-venmo-accounts'));
    const isVenmoEnabledOnBillingPage = Boolean(document.querySelector('.js-braintree-venmo-content'));

    if (isVenmoEnabledOnAccountPage || isVenmoEnabledOnBillingPage) {
        venmoBusinessLogic.init();
    }

    if (isVenmoEnabledOnAccountPage) {
        venmoBusinessLogic.initAccount();
    }

    if (isVenmoEnabledOnBillingPage) {
        venmoBusinessLogic.initBillingCheckout();
    }
}

/**
 * Inits SRC functionality on Storefront
 * @returns {void}
 */
function initSRCFunctionality() {
    const srcBusinessLogic = require('base_braintree/braintree/src/src');

    const isSrcEnabledOnAccountPage = Boolean(document.querySelector('.js-braintree-account-add-src'));
    const isSrcEnabledOnCartPage = Boolean(document.querySelector('.js-braintree-cart-src-button'));
    const isSrcEnabledOnBillingPage = Boolean(document.querySelector('.js-braintree-billing-src-button'));

    srcBusinessLogic.init();

    if (isSrcEnabledOnAccountPage) {
        srcBusinessLogic.initAccount();
    }

    if (isSrcEnabledOnCartPage) {
        srcBusinessLogic.initCartCheckout();
    }

    if (isSrcEnabledOnBillingPage) {
        srcBusinessLogic.initBillingCheckout();
    }
}

/**
 * Inits LPM functionality on Storefront
 * @returns {void}
 */
function initLPMFunctionality() {
    const lpmBusinessLogic = require('base_braintree/braintree/local/lpm');
    const isLpmEnebledOnBillingPage = Boolean(document.querySelector('.js-braintree-lpm-content'));

    if (isLpmEnebledOnBillingPage) {
        lpmBusinessLogic.init();
    }

    // LPM fallback process
    if (document.querySelector('.js-lpm-fallback')) {
        const lpmFallback = require('base_braintree/braintree/local/lpmFallBack');

        lpmFallback.process();
    }
}

/**
 * Inits APMA functionality on Storefront
 * @returns {void}
 */
function initAPMAFunctionality() {
    const apmaBusinessLogic = require('base_braintree/braintree/apma');
    const isApmaEnebled = Boolean(document.getElementById('js-apma-button-paypal'));

    if (isApmaEnebled) {
        apmaBusinessLogic.init();
    }
}

/**
 * Inits Fastlane functionality on Storefront
 * @returns {void}
 */
async function initFastlaneFunctionality() {
    const isFastlaneEnabled = Boolean(document.querySelector('.js-braintree-fastlane-content'));

    if (isFastlaneEnabled) {
        const Fastlane = require('base_braintree/braintree/fastlane/fastlane');

        await new Fastlane().init();
    }
}

// /**
//  * Handle payment`s errors on checkout page
//  * @returns {void}
//  */
function handlePaymentMethodErrorCheckout() {
    const helper = require('./helper');

    $(document).on('ajaxSuccess', function(event, jqXHR, ajaxSettings, response) {
        if (response.error && ['CheckoutServices-SubmitPayment', 'CheckoutServices-PlaceOrder'].includes(response.action)) {
            fetch(helper.getUrlWithCsrfToken(`${window.braintreeUrls.deleteSessionPayments}`))
                .catch(() => {
                    location.reload();
                });

            helper.removeActiveSessionPayment(true);
        }
    });
}
 module.exports = async function() {
    const { handleAdditionalAutotestCheckboxAttributes } = require('./helper');
    // Used only for Paypal. In future "client token" generation approach will be refactored
    const paymentMethodGeneral = require('./paymentMethodGeneral');

    // General global variables
    const isCheckoutPage = Boolean(document.getElementById('checkout-main'));
    const isAccountPage = Boolean(document.querySelector('.js-account-dashboard'));

    /* ------------------------General Account functionality------------------------ */
    if (isAccountPage) {
        const btAccountBusinessLogic = require('base_braintree/braintree/braintreeAccount');
        // Common Credit Card and SRC Account functionality

        btAccountBusinessLogic.initAccount();
    }

    /* ------------------------General Checkout functionality----------------------- */
    if (isCheckoutPage) {
        paymentMethodGeneral.fillPaymentSummaryContainer();
        paymentMethodGeneral.initPaymentMethodTabClickBehavior();
        paymentMethodGeneral.initChangeShippingBehavior();
        paymentMethodGeneral.initBillingSelectorChangeFunctionality();
        paymentMethodGeneral.initAmountObserver();
        handlePaymentMethodErrorCheckout();
        handleAdditionalAutotestCheckboxAttributes();

        const lastAmount = parseFloat(window.sessionStorage.getItem('amount-used'));
        const isSessionPaymentsEnabled = window.braintreePreferences.isSessionPaymentsEnabled;

        if (!window.braintreePreferences.isFastlaneEnabled
            && ['auto', 'always', 'always_challengeRequested'].includes(window.braintreePreferences.cc3DSecureFlow)
            && lastAmount && isSessionPaymentsEnabled) {
            paymentMethodGeneral.handleActiveCCSessionPayment(lastAmount);
        }

        /* --------------------------Fastlane--------------------------- */
        if (window.braintreePreferences.isFastlaneEnabled) {
            await initFastlaneFunctionality();
        }
    }

    /* ------------------------Credit Card------------------------ */

    initCreditCardFunctionality();

    // /* --------------------------PayPal-------------------------- */
     $('body').on('initMiniCartButton:ready', async() => {
         initApplePayFunctionality();
         await initPayPalFunctionality(paymentMethodGeneral.createGeneralClientInstancePromise());
     });

    await initPayPalFunctionality(paymentMethodGeneral.createGeneralClientInstancePromise());
    //
    // /* --------------------------GooglePay------------------------- */
    //
    initGooglePayFunctionality();
    //
    // /* --------------------------ApplePay------------------------- */
    //
    initApplePayFunctionality();
    //
    // /* --------------------------Venmo---------------------------- */
    //
    // initVenmoFunctionality();
    //
    // /* --------------------------SRC------------------------------ */
    //
    // initSRCFunctionality();
    //
    // /* --------------------------LPM-------------------------- */
    //
    // initLPMFunctionality();
    //
    // /* --------------------------APMA-------------------------- */
    //
    // initAPMAFunctionality();
};
