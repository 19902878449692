'use strict';

/* global braintree $ */

/**
 * Create Braintree Client Instance from Client Token
 * @param {string} clientToken Braintree client token
 * @returns {Promise} returns promise with Braintree Client Instance
 */
function createClientInstance(clientToken) {
    return braintree.client.create({
        authorization: clientToken
    });
}

/**
 * Fraud data generation
 * @param {Promise} btClientInstancePromise Promise with Client Instance
 * @returns {Promise} returns promise with fraud data
 */
function collectFraudData(btClientInstancePromise) {
    return btClientInstancePromise
        .then(function(btClientInstance) {
            const options = {
                client: btClientInstance,
                kount: true
            };

            if (document.getElementById('checkout-main') && window.braintreePreferences.isFastlaneEnabled) {
                options.riskCorrelationId = require('./fastlane/fastlaneHelper').getRiskCorrelationId();
            }

            return braintree.dataCollector.create(options);
        });
}

/**
 * Get array with Braintree Customer Payment Mehtods by Personalized Client Instance
 * @param {Promise} btClientInstancePromise Promise with Client Instance
 * @returns {array} array with Braintree Customer Payment Mehtod (if any) in opposite case empty array will be returned
 */
function getBtCustomerPaymentMethods(btClientInstancePromise) {
    return btClientInstancePromise
        .then(function(clientInstance) {
            return braintree.vaultManager.create({
                client: clientInstance
            });
        })
        .then(function(vaultManager) {
            return vaultManager.fetchPaymentMethods({
                defaultFirst: true
            });
        });
}

module.exports = {
    createClientInstance,
    collectFraudData,
    getBtCustomerPaymentMethods
};
