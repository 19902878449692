'use strict';

var applePayPaymentProcessingConstants = require('base_braintree/braintree/applepay/constants/applePayPaymentProcessingConstants');

/**
 * Returns current Apple Pay flow
 * @returns {string} Flow id checkout/cart
 */
function getApplePayFlowId() {
    const applePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-applepay-content'));

    return applePayEnabledOnCheckout ? applePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME : applePayPaymentProcessingConstants.FLOW_CART_NAME;
}

/**
 * Returns shipping address based on Apple Pay payment shipping contact
 * @param {Object} shippingContact Shipping address data
 * @returns {Object} Shipping address with required fields
 */
function getShippingAddress(shippingContact) {
    var fullAddress = shippingContact.addressLines[0];
    if(fullAddress && fullAddress.length >= 50){
        shippingContact.addressLines[0] = fullAddress.slice(0, 50);
    }
    return {
        streetAddress: shippingContact.addressLines[0],
        extendedAddress: shippingContact.addressLines[1],
        locality: shippingContact.locality,
        region: shippingContact.administrativeArea.toUpperCase(),
        postalCode: decodeURIComponent(shippingContact.postalCode),
        countryCodeAlpha2: shippingContact.countryCode.toUpperCase(),
        firstName: shippingContact.givenName,
        lastName: shippingContact.familyName,
        phone: shippingContact.phoneNumber,
        email: shippingContact.emailAddress
    };
}

/**
 * Returns billing address based on Apple Pay payment billing contact
 * @param {Object} billingContact Billing address data
 * @param {string} email Email
 * @param {string} phone Phone number
 * @returns {Object} Billing address with required fields
 */
function getBillingAddress(billingContact, email, phone) {
    return {
        streetAddress: billingContact.addressLines[0],
        extendedAddress: billingContact.addressLines[1],
        locality: billingContact.locality,
        region: billingContact.administrativeArea.toUpperCase(),
        postalCode: decodeURIComponent(billingContact.postalCode),
        countryCodeAlpha2: billingContact.countryCode.toUpperCase(),
        firstName: billingContact.givenName,
        lastName: billingContact.familyName,
        email: email,
        phone: phone
    };
}

/**
 * Returns Apple Pay payment object with required data for backend
 * @param {Object} sessionEvent Apple Pay Session event
 * @param {Object} tokenizedPayload Apple Pay payment data
 * @returns {Object} Apple Pay payment object with required fileds
 */
function getTokenizedPaymentData(sessionEvent, tokenizedPayload) {
    const data = {
        event: sessionEvent,
        payload: tokenizedPayload,
        nonce: tokenizedPayload.nonce,
        deviceData: document.getElementById('braintreeApplePayDeviceData').value
    };

    let shippingAddressData = sessionEvent.payment.shippingContact;

    if (shippingAddressData) {
        data.shippingAddress = getShippingAddress(shippingAddressData);
    } else {
        shippingAddressData = {
            emailAddress: document.querySelector('#emailaddress').value,
            phoneNumber: document.querySelector('[name=dwfrm_shipping_shippingAddress_addressFields_phone]').value
        };
    }

    const billingAddressData = sessionEvent.payment.billingContact;

    if (billingAddressData) {
        data.billingAddress = getBillingAddress(billingAddressData, shippingAddressData.emailAddress, shippingAddressData.phoneNumber);
    }

    return data;
}

module.exports = {
    getApplePayFlowId,
    getShippingAddress,
    getBillingAddress,
    getTokenizedPaymentData
};
