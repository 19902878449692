'use strict';

const PayPalCheckoutModel = require('../models/payPalCheckoutModel');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');
const payPalAccountList = require('./payPalList');
const helper = require('../../helper');

let braintreeClientInstancePromise;
let payPalConfigs;

/**
 * Initialize Checkout PayPal button functionality
 * @param {Promise} btClientInstancePromise BT Client Instance promise
 * @param {Object} payPalConfigurations PayPal checkout configurations object
 */
function initCheckout(btClientInstancePromise, payPalConfigurations) {
    const payPalButtonSelector = '.js-braintree-paypal-billing-button';
    const $continueButton = document.querySelector('button.submit-payment');

    const $loaderContainer = document.querySelector('.js-braintree-paypal-loader');

    const alertHandler = new PaypalAlertHandlerModel(payPalConfigurations.messages);
    const PayPalCheckoutPayment = new PayPalCheckoutModel(alertHandler,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $continueButton,
        $loaderContainer);

    // Generate Fraud Data if enabled
    PayPalCheckoutPayment.generateFraudData();
    // Init account list
    payPalAccountList.init(PayPalCheckoutPayment.fraudDataPromise);
    // Init PayPal button
    PayPalCheckoutPayment.initPayment();
}

/**
 * Initialize Checkout PayPal button functionality.
 * Additional code was added which is used for total amount extracting which is needed for "Change Payment Method button" feature
 * @param {Promise} btClientInstancePromise BT Client Instance promise
 */
function init(btClientInstancePromise) {
    const $payPalButton = document.querySelector('.js-braintree-paypal-billing-button');
    const $paypalTab = document.querySelector('.payment-options .nav-item[data-method-id="PayPal"]');

    payPalConfigs = helper.tryParseJSON($payPalButton.getAttribute('data-braintree-config'));
    braintreeClientInstancePromise = btClientInstancePromise;

    // To load PayPal button only when buyer click (or from code) on PayPal Checkout tab
    $paypalTab.addEventListener('click', function() {
        const selectedMethodID = this.dataset.methodId;

        if (selectedMethodID === 'PayPal' && !window.payPalCheckoutWasInited) {
            window.payPalCheckoutWasInited = true;

            initCheckout(braintreeClientInstancePromise, payPalConfigs);
        }
    });
}

module.exports = {
    init
};
