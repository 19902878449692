'use strict';

/**
* This file was created to provide functionality and business logic for Credit and SRC payment methods. Do not expand this file!
* Changes and additions to the code should be made to the appropriate files in the folders, depending on the payment method required, to maintain a clear code architecture.
*/

const AlertHandlerModel = require('./alertHandler');
const helper = require('./helper');

const cssClass = {
    D_NONE: 'd-none',
    FONT_WEIGHT_BOLD: 'font-weight-bold'
};

const errorHandler = new AlertHandlerModel();
let creditCardListLoader;

/**
* Shows the new card block on the Account Page
*/
function showAddNewCardOptions() {
    const addNewCardButtonEl = document.querySelector('.js-braintree-add-new-card-btn');
    const addNewCardBlockEl = document.querySelector('.js-braintree-add-new-card-block');

    // Hide "Credit Card error message" container
    errorHandler.hideAlerts();
    // Hide "Add New Card" button
    addNewCardButtonEl.classList.add(cssClass.D_NONE);
    // Display "Add New Card" form
    addNewCardBlockEl.classList.remove(cssClass.D_NONE);
}

/**
 * Removes src from Cards list
 */
function handleSrcRemoval() {
    const srcCardSectionHeaderEl = document.getElementById('src-header');

    if (srcCardSectionHeaderEl) {
        const srcCardCount = parseInt(srcCardSectionHeaderEl.dataset.cardCount);

        if (srcCardCount > 1) {
            srcCardSectionHeaderEl.setAttribute('data-card-count', srcCardCount - 1);
        } else {
            srcCardSectionHeaderEl.remove();
        }
    }
}

/**
 * Removes card from Credit/SRC Cards list
 * @param {Object} e Event object
 */
function initRemoveCardEvent() {
    const removeCardBtnEls = document.querySelectorAll('.js-credit-card-accounts .js-remove-bt-payment');

    removeCardBtnEls.forEach((el) => el.addEventListener('click', function(e) {
        const target = e.target;

        creditCardListLoader.show();

        fetch(helper.getUrlWithCsrfToken(`${window.braintreeUrls.deletePaymentUrl}?UUID=${target.getAttribute('data-id')}`), { method: 'GET' })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error && data.error.message) {
                    // Show error message in a case of current PI removal is not allowed
                    errorHandler.showError(data.error.message);
                } else {
                    const paymentMethod = target.closest('.js-stored-card')?.dataset.paymentMethod;

                    if (paymentMethod === window.braintreeConstants.PAYMENT_METHOD_ID_SRC) {
                        handleSrcRemoval();
                    }

                    document.getElementById(`uuid-${data.UUID}`).remove();

                    if (data.newDefaultAccount) {
                        document.getElementById(`uuid-${data.newDefaultAccount}`).classList.add(cssClass.FONT_WEIGHT_BOLD);
                        document.getElementsByClassName(`uuid-${data.newDefaultAccount}`).classList.add(cssClass.D_NONE);
                    }
                }

                creditCardListLoader.hide();
            })
            .catch(() => {
                location.reload();
            });
    }));
}

/**
 * Select default card in Credit/SRC Cards list
 * @param {Object} e Event object
 */
function initMakeDefaultCardEvent() {
    const makeDefaultCardBtnEls = document.querySelectorAll('.js-credit-card-accounts .js-braintree-make-default-card');

    makeDefaultCardBtnEls.forEach((el) => el.addEventListener('click', function(e) {
        const target = e.target;
        const url = window.braintreeUrls.makePaymentMethodDefaultUrl;
        const id = target.getAttribute('data-id');
        const paymentMethodID = 'CARD';

        // Hide "Credit Card error message" container
        errorHandler.hideAlerts();
        creditCardListLoader.show();

        fetch(helper.getUrlWithCsrfToken(`${url}?UUID=${id}&pmID=${paymentMethodID}`))
            .then((response) => response.json())
            .then((data) => {
                // New default Card changes
                document.querySelector(`#uuid-${data.newDefaultProperty} span`).classList.add(cssClass.FONT_WEIGHT_BOLD);
                document.querySelector('.js-braintree-make-default-card.uuid-' + data.newDefaultProperty).classList.add(cssClass.D_NONE);

                // Previous default Card changes
                document.querySelector(`#uuid-${data.toRemoveDefaultProperty} span`).classList.remove(cssClass.FONT_WEIGHT_BOLD);
                document.querySelector('.js-braintree-make-default-card.uuid-' + data.toRemoveDefaultProperty).classList.remove(cssClass.D_NONE);

                creditCardListLoader.hide();
            })
            .catch(() => {
                creditCardListLoader.hide();
            });
    }));
}

/**
 * Inits braintree account page functionality
 */
function initAccount() {
    const loaderInstance = require('./loaderHelper');

    const creditCardAccountBtLoaderEl = document.getElementById('creditCardAccountBtLoader');
    const addNewCardBtnEl = document.querySelector('.js-braintree-add-new-card-btn');
    const submitCwppUnlinkBtnEl = document.querySelector('.js-unlink-submit');
    const unlinkSectionEl = document.querySelector('.js-unlink-section');

    const creditCardListLoaderExists = Boolean(creditCardAccountBtLoaderEl);

    if (creditCardListLoaderExists) {
        creditCardListLoader = loaderInstance(creditCardAccountBtLoaderEl);
    }

    if (addNewCardBtnEl) {
        // "Add new card" area link functionality initiation
        addNewCardBtnEl.addEventListener('click', showAddNewCardOptions);
    }

    // User unlinking functionality initiation
    if (submitCwppUnlinkBtnEl && unlinkSectionEl) {
        submitCwppUnlinkBtnEl.addEventListener('click', () => {
            fetch(helper.getUrlWithCsrfToken(window.braintreeUrls.unlinkCwppUrl), {
                method: 'POST'
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.errorMessage) {
                        errorHandler.showError(res.errorMessage);
                    } else {
                        unlinkSectionEl.classList.add('d-none');

                        errorHandler.showInfo(res.alertMessage);
                    }
                })
                .catch(() => {
                    location.reload();
                });
        });
    }

    // "Make default" link functionality initiation
    initMakeDefaultCardEvent();

    // "Remove Card" link functionality initiation
    initRemoveCardEvent();

    helper.paymentInstrumentsObserver(
        document.querySelectorAll('.js-credit-cards, .js-src-cards, .js-paypal-accounts, .js-venmo-accounts')
    );
}

module.exports = {
    initAccount,
    initRemoveCardEvent,
    initMakeDefaultCardEvent
};
