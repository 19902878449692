'use strict';

const SrcBaseModel = require('./srcBaseModel');
const helper = require('../../helper');

// See description of "srcBaseModel" for more info

/**
 * Creates a new SrcAccountModel instance
 * @param {Promise} clientInstancePromise client instance promise.
 * @param {Object} srcButtonConfigurations - Braintree SRC configs.
 * @param {Object} $braintreeSrcDeviceDataInput - input data.
 * @param {Instance} loader - instance of loaderInstance
 * @param {Instance} alertHandler - instance of SrcAlertHandlerModel
 */
function SrcAccountModel(clientInstancePromise, srcButtonConfigurations, $braintreeSrcDeviceDataInput, loader, alertHandler) {
    SrcBaseModel.call(this,
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        loader,
        alertHandler);
}

SrcAccountModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Method set nonce data in to hidden input and submit the form to the server
SrcAccountModel.prototype.onTokenizeCallback = function(srcPayload) {
    SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);
    const srcLoader = this.loader;
    const alertHandler = this.alertHandler;
    const addSrcAccountFormEl = document.querySelector('.js-braintree-account-add-src');
    const addSrcFormUrl = addSrcAccountFormEl.getAttribute('action');
    const srcNonceEl = document.getElementById('braintreeSrcNonce');
    const srcBillingAddressEl = document.getElementById('braintreeSrcBillingAddress');

    srcLoader.show();

    srcNonceEl.value = srcPayload.nonce;
    srcBillingAddressEl.value = JSON.stringify(srcPayload.billingAddress);

    return helper.checkForDuplicatedCC(srcPayload)
        .then(function(result) {
            if (result.error) {
                throw result.message;
            }
        })
        .then(function() {
            const creditCardAccount = require('../../creditcard/components/creditCardAccount');
            const braintreeAccount = require('../../braintreeAccount');

            const formData = new FormData(addSrcAccountFormEl);
            const encodedCCData = window.btoa(JSON.stringify(Object.fromEntries(formData)));

            return fetch(helper.getUrlWithCsrfToken(addSrcFormUrl), {
                method: 'POST',
                body: encodedCCData,
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        alertHandler.showError(data.error);
                    } else {
                        fetch(data.renderAccountsUrl)
                            .then((template) => template.text())
                            .then((templateHtml) => {
                                creditCardAccount.closeAddNewCardBlock();
                                document.querySelector('.js-src-cards').innerHTML = templateHtml;
                                // "Make default" link functionality reinitiation
                                braintreeAccount.initMakeDefaultCardEvent();
                                // "Remove Card" link functionality reinitiation
                                braintreeAccount.initRemoveCardEvent();
                            });
                    }
                });
        })
        .catch(function(error) {
            srcLoader.hide();
            alertHandler.showError(error);
        })
        .finally(function() {
            srcLoader.hide();
        });
};

// See description of "srcBaseModel" for more info
SrcAccountModel.prototype.initSrcButton = function() {
    const SrcAccountModelInstance = this;

    SrcAccountModelInstance.braintreeSrcSdkButtonInit(SrcAccountModelInstance)
        .catch(function(error) {
            SrcAccountModelInstance.alertHandler.handleSrcError(error);
        });
};

module.exports = SrcAccountModel;
