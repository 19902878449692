const helper = require('../../helper');

const $srcAccountList = document.getElementById('braintreeSrcAccountsList');
const $saveSRCAccountCheckbox = document.getElementById('braintreeSaveSRCAccount');
const $saveSRCAccountCheckboxContainter = document.getElementById('braintreeSaveSRCContainer');
const $srcButton = document.querySelector('.js-braintree-src-button');
const $accoutnListBlock = document.querySelector('.js-braintree-used-src-account');
const $alertInfo = document.querySelector('.js-billing-address-info-alert');

/**
 * Sets value to data-paypal-is-hide-continue-button attribute
 * @param {boolean} value true/false
 */
function setIsHideContinueButtonAttr(value) {
    const $srcContent = document.querySelector('.js-braintree-src-content');

    $srcContent.setAttribute('data-paypal-is-hide-continue-button', value);
}

/**
 * Handles actions based on selected value in SRC dropdown for checkout view
 */
function srsListStateHandler() {
    const srcOptionId = $srcAccountList.value;

    if (srcOptionId === 'newaccount') {
        if ($alertInfo) {
            $alertInfo.style.display = 'block';
        }

        $accoutnListBlock.style.display = 'none';
    } else {
        $srcButton.style.display = 'none';

        if ($alertInfo) {
            $alertInfo.style.display = 'none';
        }

        helper.continueButtonToggle(false);
        setIsHideContinueButtonAttr(false);
    }
}

/**
 * Get stored card by card type and last four digits
 * @param {string} cardType card type
 * @param {string} lastFour last four card digits
 * @returns {Object} jquery object of found stored card
 */
function getStoredCardByData(cardType, lastFour) {
    return Array.from(document.querySelectorAll('.js-stored-card')).find(function($card) {
        const storedCardType = $card.dataset.type.replace(/\s/g, '').toLowerCase();
        const storedCardLastFour = $card.dataset.lastFour.toString();

        return storedCardType === cardType && storedCardLastFour === lastFour;
    });
}

/**
 * Checks whether SRC session card is already stored
 * @param {string} cardType card type
 * @param {string} lastFour last four card digits
 * @returns {boolean} "true/false" value whether session card is already stored
 */
function isSessionCardAlreadyStored(cardType, lastFour) {
    const $storedCard = getStoredCardByData(cardType, lastFour);
    let flag = false;

    if ($storedCard) {
        flag = true;
    }

    return flag;
}

/**
 * Sets value to "Save SRC account" checkbox
 * @param {Element} srcSessionAccount element from which we recognize session account existence
 * @param {boolean} value true/false
 */
function handleSaveSrcAccountCheckbox(srcSessionAccount, value) {
    const isSrcSessionCardExist = srcSessionAccount?.value !== '';
    const isAddNewOptionSelected = $srcAccountList.value === 'newaccount';

    if ($saveSRCAccountCheckbox) {
        // In case if session account exists and is chosen in dropdown
        // we check whether used SRC card is already stored
        // if card is already stored "save" checkbox is hidden and unchecked
        if (isSrcSessionCardExist && !isAddNewOptionSelected) {
            const $braintreeSrcCardType = document.getElementById('braintreeSrcCardType');
            const $braintreeSrcLastFourDigits = document.getElementById('braintreeSrcLastFourDigits');

            const sessionCardLastFour = $braintreeSrcLastFourDigits.value;
            let sessionCardType = $braintreeSrcCardType.value;

            sessionCardType = sessionCardType.replace(/\s/g, '').toLowerCase();

            const isSrcSessionCardAlreadyStored = isSessionCardAlreadyStored(sessionCardType, sessionCardLastFour);

            if (isSrcSessionCardAlreadyStored) {
                $saveSRCAccountCheckbox.checked = false;
                $saveSRCAccountCheckboxContainter.style.display = 'none';
                helper.setCheckboxAutotestAttributes($saveSRCAccountCheckbox);
            }

            return;
        }

        // In case if session card does not exist/user chooses option "Add New"
        // or session card is not stored yet
        // checkbox is checked and shown
        $saveSRCAccountCheckboxContainter.style.display = 'block';
        $saveSRCAccountCheckbox.checked = value;
        helper.setCheckboxAutotestAttributes($saveSRCAccountCheckbox);
    }
}

module.exports = {
    setIsHideContinueButtonAttr,
    srsListStateHandler,
    handleSaveSrcAccountCheckbox
};
