/* eslint-disable no-console */

'use strict';

/* global Promise braintree $ */

var helper = require('../../helper');
var btCreditCardModel = require('../braintreesdk/braintreeCreditCardModel');
var creditCardPaymentProcessingHelper = require('../helpers/creditCardPaymentProcessingHelper');
// var loaderInstance = require('base_braintree/braintree/loaderHelper');
var creditCardPaymentProcessingConstants = require('base_braintree/braintree/creditcard/constants/creditCardPaymentProcessingConstants');
var creditCardHelper = require('base_braintree/braintree/creditcard/helpers/creditCardHelper');

var creditCardBillingAddressHelper = require('.././helpers/creditCardBillingAddressHelper');
var formValidation = require('customBase/components/formValidation');

var $continueButton = document.querySelector('button.submit-payment');
var $addNewCardButton = document.querySelector('button.submit-newcard');
var $creditCardList = document.getElementById('braintreeCreditCardList');
// var $braintreeCreditCardLoader = document.getElementById('braintreeCreditCardLoader');
var $customCreditCardErrorContainer = document.getElementById('customCreditCardErrorContainer');

var loader;
var alertHandler;

var hfInstance;

/**
 * Checking for customError property in payload
 * @param {Object} payload object with btPayload
 * @throws {Object} when customError property exists in payload
 */
function checkForErrorInPayload(payload) {
    if (Object.hasOwnProperty.call(payload, 'customError')) {
        throw payload;
    }
}

/**
 * Process a stored card
 * @param {clientInstancePromise} clientInstancePromise A ClientInstancePromise
 * @param {string} email An email address
 * @param {string} billingData A billing address data
 * @returns {Promise} A promise
 */
var processStoredCard = (clientInstancePromise, email, billingData) => {
    const savedCcBillingAddressAsString = creditCardHelper.getSavedCcBillingAddressAsString();

    // Fills a credit card billing address input with billing address data
    if (savedCcBillingAddressAsString) {
        // Case when a Credit card has a saved billing address
        // Using only for updating a billing address on the server side
        creditCardBillingAddressHelper.setCreditCardBillingAddress(helper.tryParseJSON(savedCcBillingAddressAsString));
    } else {
        // Case when a Credit Card does not have a saved billing address
        creditCardBillingAddressHelper.setCreditCardBillingAddress(creditCardBillingAddressHelper.createCreditCardBillingAddress());
    }

    const $selectedCcOption = creditCardHelper.getSelectedCcAccountOption();
    const selectedCcOptionId = $selectedCcOption.value;
    const isSecureRemoteCommerce = $selectedCcOption.dataset.paymentMethod?.toLowerCase() === 'src';
    const cvvOnlyNonceEl = document.getElementById('braintreeCvvOnlyNonce');

    if (isSecureRemoteCommerce && cvvOnlyNonceEl) {
        cvvOnlyNonceEl.value = '';
    }

    if (hfInstance.isCcReVerifyRequired(selectedCcOptionId) && !isSecureRemoteCommerce) {
        hfInstance
            .tokenizeCvv()
            .then(tokenizePaylaod => {
                hfInstance.initPaymentDetailsSelectorObserver(selectedCcOptionId);
                cvvOnlyNonceEl.value = tokenizePaylaod.btTokenizePayload.nonce;
            }).catch(() => {
                cvvOnlyNonceEl.value = 'error';
            });
    }

    return creditCardPaymentProcessingHelper.getNonceFromStoredCard(clientInstancePromise, $creditCardList)
        .then(function(response) {
            if (!response) {
                throw Error('Payment method was not found in vault manager.');
            }

            return btCreditCardModel.getAuthInsightRegulationEnvironment(response.nonce);
        })
        .then(function(response) {
            return creditCardPaymentProcessingHelper.getNonceFromStoredCard(clientInstancePromise, $creditCardList)
                .then(function(paymentMethodResponse) {
                    paymentMethodResponse.authenticationInsight = {
                        regulationEnvironment: response.regulationEnvironment
                    };

                    return btCreditCardModel.processStoredCard(email, billingData, paymentMethodResponse)
                        .then(function(btStoredCardPayload) {
                            checkForErrorInPayload(btStoredCardPayload);

                            const threeDSecureDataValidationPayload = btStoredCardPayload.threeDSecureDataValidationPayload;

                            if (threeDSecureDataValidationPayload) {
                                creditCardPaymentProcessingHelper.fill3DsData(threeDSecureDataValidationPayload);
                            } else {
                                document.getElementById('braintreeCreditCardNonce').value = btStoredCardPayload.nonce;
                            }

                            return Promise.resolve(btStoredCardPayload);
                        });
                });
        });
};

/**
 * Processes a new card
 * @param {Object} btPayload Payload from Braintree
 * @returns {Promise} A promise
 */
var processNewCard = (btPayload) => {
    const $addCardModal = document.getElementById('addCardModal');
    const isNewCardModal = ($addCardModal !== null && $addCardModal.classList.contains('show')) ? 'addCreditCardForm' : 'dwfrm_billing';
    var isSessionPaymentsEnabled = window.braintreePreferences.isSessionPaymentsEnabled;

    // Fills a credit card billing address input with billing address data
    creditCardBillingAddressHelper.setCreditCardBillingAddress(creditCardBillingAddressHelper.createCreditCardBillingAddress());

    document.getElementById('braintreeCreditCardBin').value = btPayload.btTokenizePayload.details.bin;

    // Fill hidden inputs CreditCard fields and Session CreditCard div fields
    creditCardPaymentProcessingHelper.fillCreditCardFormWithPayloadData(btPayload);

    // Filling of session card attributes for using them in business logic (client/server)
    creditCardPaymentProcessingHelper.setCreditCardDataAttributes(btPayload, isSessionPaymentsEnabled);

    if (isSessionPaymentsEnabled) {
        var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');

        // Fill 'toDisplay' fields with data to display them for buyer
        creditCardPaymentProcessingHelper.fillSessionCreditCardFormWithPayloadData(btPayload);
        // Select "Session Account Option" in "Account List"
        creditCardPaymentProcessingHelper.markAsSelectedSessionAccount();
        // Display "Stored Accounts" list
        creditCardPaymentProcessingHelper.displayStoredCreditCardList();
        // Show "Session Credit Card" fields (in div blocks)
        creditCardFormFieldHelper.showCardElements(creditCardFormFieldHelper
            .getCCFieldsToDisplay().asArray.concat(creditCardFormFieldHelper.getCcCvvToDisplayField().asArray));
        // Hide hosted fields (iframe fields)
        creditCardFormFieldHelper.hideCardElements(creditCardFormFieldHelper
            .getCCFields().asArray.concat(creditCardFormFieldHelper.getCcCvvField().asArray));
    }

    // Clear "Hosted Fields" (from iframe)
    hfInstance.clearHostedFields();
    if(!isNewCardModal) {
        helper.continueButtonToggle(false);
    }

    return Promise.resolve(btPayload);
};

/**
 * Process Credit Card on the Billing Page
 * @param {Object} event Event object
 * @returns {void}
 */
function processCreditCard(event) {
    event.preventDefault();
    event.stopPropagation();

    // Removes error messages on the Billing Page
    alertHandler.hideAlerts();

    try {
        const customErrorMessages = helper.tryParseJSON($customCreditCardErrorContainer.getAttribute('data-errors'));
        const basketDataValidResponse = btCreditCardModel.isBasketDataValid(customErrorMessages.TOTAL_BASKET_AMOUNT_INVALID);
        const clientInstancePromise = btCreditCardModel.getClientInstancePromise();
        const creditCardFlow = creditCardPaymentProcessingHelper.getCreditCardFlowID($creditCardList);
        // const isNewCardModal = event.target.id === 'addCreditCardForm';
        const $addCardModal = document.getElementById('addCardModal');
        const isNewCardModal = $addCardModal !== null && $addCardModal.classList.contains('show');

        if (basketDataValidResponse.error) {
            throw basketDataValidResponse.customErrorMessage;
        }

        // Data passed inside "braintreeCreditCardModel"
        const billingData = helper.getBillingAddressFormValues();
        const email = document.querySelector('.customer-summary-email').textContent;
        const isCheckoutFlow = true;

        let result;

        // "Hosted Field Instance" validation
        const sdkHfInstanceValResult = hfInstance.sdkHfInstanceValidation();

        if (!sdkHfInstanceValResult.exist) {
            throw sdkHfInstanceValResult.errorMessage;
        }
        
        loader.show();

        const $billingForm = document.getElementById(isNewCardModal ? 'addCreditCardForm' : 'dwfrm_billing');

        helper.clearForm($billingForm);

        switch (creditCardFlow) {
            // Case for new card
            case creditCardPaymentProcessingConstants.FLOW_NEW_CARD_NAME:
                result = btCreditCardModel.processNewCard(email, billingData, isCheckoutFlow, hfInstance)
                    .then(async(btPayload) => {
                        var isValidCardholderName = helper.validateCardholderName(btPayload, $billingForm);
                        checkForErrorInPayload(btPayload);
                        checkSameAsShipping(btPayload); // Keep this function above always form validation
                        if (!helper.validateForm($billingForm) || !isValidCardholderName) {
                            loader.hide();
                            return Promise.reject(customErrorMessages.VALIDATION_INVALID);
                        }

                        // const $saveCardCheckbox = document.getElementById('braintreeSaveCreditCard');
                        //
                        // if ($saveCardCheckbox && $saveCardCheckbox.checked) {
                        //     const response = await helper.checkForDuplicatedCC(btPayload);
                        //
                        //     if (response.error) {
                        //         return Promise.reject(response.message);
                        //     }
                        // }

                        return btPayload;
                    })
                    .then((btPayload) => processNewCard(btPayload));

                break;

            // Case for session card
            case creditCardPaymentProcessingConstants.FLOW_SESSION_CARD_NAME:
                result = btCreditCardModel.processSessionCard();

                break;

            // Case for stored card
            case creditCardPaymentProcessingConstants.FLOW_STORED_CARD_NAME:
                result = processStoredCard(clientInstancePromise, email, billingData);

                break;
            default:
                // Handle error with wrong checkout flow
                result = Promise.reject(customErrorMessages.WROND_CHECKOUT_FLOW);

                break;
        }

        result
            .then(function() {
                loader.hide();
                if(isNewCardModal) {
                    addCreditCardForm()
                } else {
                    updateStateValue();
                    // Trigger "Submit Payment" button
                    event.target.click();
                }
            })
            .catch(function(error) {
                loader.hide();

                // Braintree Errors
                alertHandler.handleCreditCardError(error, hfInstance.getCcFields());

                helper.validateForm($billingForm);
            });
    } catch (error) {
        loader.hide();
        alertHandler.handleCreditCardError(error);
    }
}
function updateStateValue() {
    var $form = $('.billing-payment-sec');
    var country = $form.find('#billingCountry').val();
    var billingStatedefault = $form.find('#billingStatedefault');
    if (country === 'US') {
        billingStatedefault.val($form.find('#billingStateUS').val());
    } else if (country === 'CA') {
        billingStatedefault.val($form.find('#billingStateNonUS').val());
    } else {
        var countryWithState = $form.find('#countryWithState').val();
        if (countryWithState.indexOf(country) > -1) {
            billingStatedefault.val($form.find('#billingStateText').val());
        } else {
            billingStatedefault.val('');
        }
    }
}
/**
 * Check for Same shipping address
 * @param {Object} btPayload Passing TokenizePayload
 **/
function checkSameAsShipping(btPayload) {
    if (!$('#sameasshippingselector').is(':checked')) {
        if(btPayload.btTokenizePayload && btPayload.btTokenizePayload.details && btPayload.btTokenizePayload.details.cardholderName) {
            var firstName = btPayload.btTokenizePayload.details.cardholderName;
            var lastName = firstName;
            if (firstName && firstName.length > 0 && firstName.split(' ').length > 1) {
                var a = firstName;
                var b = a.split(' ');
                var c = b[0];
                firstName = c;
                lastName = a.split(c)[1].trim();
            }
            $('#billingFirstName').val(firstName);
            $('#billingLastName').val(lastName);
        }
    }
}
function addCreditCardForm() {
    loader.show();
    var addCreditCardForm = $('#addCreditCardForm');
    var country = addCreditCardForm.find('#billingCountry').val();
    addCreditCardForm.find('.alert').remove();
    if (country === 'US') {
        addCreditCardForm.find('#billingStatedefault').val(addCreditCardForm.find('#billingStateUS').val());
    } else if (country === 'CA') {
        addCreditCardForm.find('#billingStatedefault').val(addCreditCardForm.find('#billingStateNonUS').val());
    } else {
        var countryWithState = addCreditCardForm.find('#countryWithState').val();
        if (countryWithState.indexOf(country) > -1) {
            addCreditCardForm.find('#billingStatedefault').val(addCreditCardForm.find('#billingStateText').val());
        } else {
            addCreditCardForm.find('#billingStatedefault').val('');
        }
    }
    if ($('.spc-billing-same-as-shipping-modal:checkbox:checked').length === 0) {
        updateBillingForm();
    }
    $.ajax({
        url: addCreditCardForm.attr('action'),
        type: 'post',
        dataType: 'json',
        data: addCreditCardForm.serialize(),
        success: function (data) {
           loader.hide();
            if (!data.success) {
                formValidation(addCreditCardForm[0], data);
            } else {
                $('#addCardModal').modal('hide');
                location.reload();
            }
        },
        error: function (err) {
           loader.hide();
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}
function updateBillingForm() {
    $('.billing-address-block .billing-address').find('.billingFirstName').val($('#addCardModal .billingFirstName').val());
    $('.billing-address-block .billing-address').find('.billingLastName').val($('#addCardModal .billingLastName').val());
    $('.billing-address-block .billing-address').find('.billingZipCode').val($('#addCardModal .billingZipCode').val());
    $('.billing-address-block .billing-address').find('.billingState').val($('#addCardModal .billingState').val());
    $('.billing-address-block .billing-address').find('.billingAddressCity').val($('#addCardModal .billingAddressCity').val());
    $('.billing-address-block .billing-address').find('.billingAddressOne').val($('#addCardModal .billingAddressOne').val());
    $('.billing-address-block .billing-address').find('.phone').val($('#addCardModal .phone').val());
}
/**
 * Inits Credit Card process on the Billing Page
 * @param {Constructor} alertHandlerModel Alert handling constructor
 * @param {Object} hostedFieldsInstance A hosted fields instance
 */
function init(alertHandlerModel, hostedFieldsInstance) {
    // loader = loaderInstance($braintreeCreditCardLoader);
    loader = $('.loader-preventive');
    alertHandler = alertHandlerModel;
    hfInstance = hostedFieldsInstance;

    $continueButton.addEventListener('click', function(event) {
        if(!$continueButton.classList.contains('js-skip-creditcard-validation')) {
            //const isCreditCardTabActive = creditCardPaymentProcessingHelper.isActiveCreditCardTab();
    
            // Removes active session payment method once 'Next: Place order' button clicked
            helper.removeActiveSessionPayment();
    
           if (!event.isTrusted) {
               return;
           }
            processCreditCard(event);

        }
    });

    if ($addNewCardButton !== null) {
        $addNewCardButton.addEventListener('click', function(event) {
            //const isCreditCardTabActive = creditCardPaymentProcessingHelper.isActiveCreditCardTab();

            // Removes active session payment method once 'Next: Place order' button clicked
            helper.removeActiveSessionPayment();

        if (!event.isTrusted) {
            return;
        }
            processCreditCard(event);
        });
    }
}

module.exports = {
    init
};
