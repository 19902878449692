'use strict';

/* global braintreeUtils braintree $ */
let btClientInstancePromise;

/**
 * Returns a promise that resolves with the HostedFields instance.
 * @param {Object} hostedFieldsParams Object of hosted fieds parameters
 * @returns {Object} Object with hosted field parameters
 */
function createHostedFields(hostedFieldsParams) {
    return btClientInstancePromise
        .then(function(btClientInstance) {
            return braintree.hostedFields.create({
                client: btClientInstance,
                styles: hostedFieldsParams.styles,
                fields: hostedFieldsParams.configs
            });
        });
}

/**
 * Parse the credit cart billingAddress to 3DS form
 * @param {Object} billingData credit cart billingAddress
 * @returns {Object} parsed to 3DS form billing data
 */
function parseCreditCartBillingDataTo3DSForm(billingData) {
    return {
        givenName: billingData.firstName,
        surname: billingData.lastName,
        phoneNumber: billingData.phone,
        streetAddress: billingData.address1,
        extendedAddress: billingData.address2,
        locality: billingData.city,
        region: billingData.stateCode,
        postalCode: decodeURIComponent(billingData.postalCode),
        countryCodeAlpha2: billingData.country
    };
}

/**
 * Create and send the 3D Secure request
 * @param {Object} btClientInstance Client instance
 * @param {Object} verifyCardObject Object with Credit Card info for verification
 * @param {string} threeDSFlowType 3DSecure flow value
 * @returns {Object} 3DS instance
 */
function create3DSecureRequest(btClientInstance, verifyCardObject, threeDSFlowType) {
    return braintree.threeDSecure.create({
        client: btClientInstance,
        version: 2
    })
        .then(function(threeDSecure) {
            threeDSecure.on('customer-canceled', () => threeDSecure.cancelVerifyCard());
            threeDSecure.on('lookup-complete', (data, next) => {
                next();
            });

            if (window.braintreePreferences.cc3DSecureSCA !== 'disabled'
                && ['always', 'auto'].includes(threeDSFlowType)) {
                verifyCardObject.requestedExemptionType = window.braintreePreferences.cc3DSecureSCA;
            } else if (threeDSFlowType === 'always_challengeRequested') {
                verifyCardObject.challengeRequested = true;
            }

            return threeDSecure.verifyCard(verifyCardObject);
        })
        .catch((error) => {
            const helper = require('../../helper');
            const customCreditCardErrorContainerEl = document.getElementById('customCreditCardErrorContainer');
            const googlePayBtnEl = document.querySelector('.js-braintree-googlepay-button');
            const customErrorList = customCreditCardErrorContainerEl
                ? helper.tryParseJSON(customCreditCardErrorContainerEl.dataset.errors)
                : helper.tryParseJSON(googlePayBtnEl.dataset.braintreeConfig).messages;

            if (error.code === window.braintreeConstants.THREEDS_CARD_VERIFY_CANCELED) {
                throw new Error(customErrorList.CARD_VERIFY_CANCELED_MESSAGE);
            }

            throw new Error(customErrorList.THREEDS_GENERAL_ERROR);
        });
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {Object} verifyCardObject Object with Credit Card info for verification
 * @param {string} threeDSecureFlow 3DSecure flow value
 * @returns {Object} 3DS instance
 */
function apply3dSecureValidation(verifyCardObject, threeDSecureFlow) {
    verifyCardObject.billingAddress = parseCreditCartBillingDataTo3DSForm(verifyCardObject.billingAddress);

    return btClientInstancePromise
        .then(function(btClientInstance) {
            return create3DSecureRequest(btClientInstance, verifyCardObject, threeDSecureFlow);
        });
}

/**
 * Tokenizes a Credit Card and returns a nonce payload.
 * @param {HostedFileds} hfInstance Hosted fieled instance
 * @param {Object} tokenizationOptions Credit Card tokenization option
 * @returns {Promise} A promise that resolves with a tokenizePayload.
 */
function tokenize(hfInstance, tokenizationOptions) {
    const tokenizeOption = tokenizationOptions || {};

    return hfInstance.tokenize(tokenizeOption);
}

/**
 *
 * @param {Promise} braintreeClientInstancePromise Braintree Credit Card instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    init,
    createHostedFields,
    apply3dSecureValidation,
    tokenize,
    create3DSecureRequest
};
