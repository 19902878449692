'use strict';

var helper = require('../../helper');
var paymentMethodGeneral = require('../../paymentMethodGeneral');
var $applePayButton = document.querySelector('.js-braintree-applepay-button');

var paymentData;
var $braintreeCartButtons;
var applePayCheckoutFormData;
var alertHandler;

/**
 * Expends Apple Pay Checkout Form with required data
 */
function expandApplePayCheckoutFormData() {
    const $csrfToken = document.querySelector('.js-braintree-cart-buttons-wrap #csrf_token');

    applePayCheckoutFormData.append($csrfToken.name, $csrfToken.value);
    applePayCheckoutFormData.append('braintreeApplePayNonce', paymentData.nonce);
    applePayCheckoutFormData.append('braintreeApplePayDeviceDataInput', paymentData.deviceData);
    applePayCheckoutFormData.append('braintreeApplePayShippingAddress',
        JSON.stringify(paymentData.shippingAddress) || '{}');
    applePayCheckoutFormData.append('braintreeApplePayBillingAddress',
        JSON.stringify(paymentData.billingAddress) || '{}');
    const billingField = Boolean(document.querySelector('.js-braintree-billingAddress'));
    if(billingField){
        var storeFrontBillingData = JSON.parse(document.querySelector('.braintree-billing-payment-wrap').getAttribute('data-billing-form-fields-names'));
        var applePayBillingData = paymentData.billingAddress;
        storeFrontBillingData.dwfrm_billing_addressFields_firstName = applePayBillingData.firstName;
        storeFrontBillingData.dwfrm_billing_addressFields_lastName = applePayBillingData.lastName;
        storeFrontBillingData.dwfrm_billing_addressFields_address1 = applePayBillingData.streetAddress;
        storeFrontBillingData.dwfrm_billing_addressFields_address2 = applePayBillingData.extendedAddress || '';
        storeFrontBillingData.dwfrm_billing_addressFields_city = applePayBillingData.locality;
        storeFrontBillingData.dwfrm_billing_addressFields_postalCode = applePayBillingData.postalCode;
        storeFrontBillingData.dwfrm_billing_addressFields_states_stateCode = applePayBillingData.state;
        storeFrontBillingData.dwfrm_billing_addressFields_country = applePayBillingData.countryCodeAlpha2;
        storeFrontBillingData.dwfrm_billing_contactInfoFields_email = applePayBillingData.email;
        storeFrontBillingData.dwfrm_billing_contactInfoFields_phone = applePayBillingData.phone;
        helper.updateBillingFormValues(storeFrontBillingData);
    }
}

/**
 * Process Apple Pay on the Cart Page
 */
function processCartFlow() {
    const $applePayButton = document.querySelector('.js-braintree-applepay-button');
    const billingAddressData = paymentData.billingAddress;
    const paymentMethodName = helper.tryParseJSON($applePayButton.getAttribute('data-braintree-config')).paymentMethodName;
    const placeOrderUrl = window.braintreeUrls.placeOrderUrl;
    const checkoutSubmitPayment = window.braintreeUrls.checkoutSubmitPayment;
    const checkoutFormFields = $braintreeCartButtons.getAttribute('data-checkout-form-fields');
    const paymentFieldData = helper.getPaymentFieldsData(billingAddressData, paymentMethodName);

    applePayCheckoutFormData = helper.createPaymentFormData(checkoutFormFields, paymentFieldData);
    applePayCheckoutFormData.append('pageFlowCart', true);
    expandApplePayCheckoutFormData();
    // submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(billingAddressData.email);

    fetch(helper.getUrlWithCsrfToken(checkoutSubmitPayment), {
        method: 'POST',
        body: applePayCheckoutFormData
    })
        .then((response) => response.json())
        .then((data) => {
            if (!helper.handleSubmitPaymentErrors(data, alertHandler)) {
                window.location.href = placeOrderUrl;
            }
        })
        .catch((error) => {
            if (error && error.redirectUrl) {
                window.location.href = error.redirectUrl;
            }
        });
}

/**
 * Inits Apple Pay Cart components
 * @param {Object} data Apple Pay payment data
 * @param {Constructor} alertHandlingModel Alert handling model
 */
function init(data, alertHandlingModel) {
    paymentData = data;
    const $applePayButton = document.querySelector('.js-braintree-applepay-button');
    $braintreeCartButtons = document.querySelector('.js_braintree_applepayButtonsWrap');
    $applePayButton.setAttribute('data-is-inited', true);
    alertHandler = alertHandlingModel;

    processCartFlow();
}

module.exports = {
    init
};
