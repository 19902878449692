'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const helper = require('../../helper');
const payPalAccountModelHelper = require('../helpers/payPalAccountModel');
const billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');

const $loaderContainer = document.querySelector('.braintree-loader-container');

/**
 * @param {Object} apmaModelInstance apma model instance
 * Save address details to address book from PP side
 * @returns {Promise} apmaModelInstance
 */
function saveAddressDetails(apmaModelInstance) {
    return apmaModelInstance.savePaypalDefaultAddress()
        .then(() => {
            window.location.href = apmaModelInstance.redirectUrl;
        });
}

/**
 * PayPal APMA model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations Configs from server (taken from data attr)
 * @param {Object} additional - Additional data
 * @param {string} additional.addPayPalAccountUrl Add PayPal account URL
 * @param {Object} additional.addressObject Address object we get from CWPP
 * @param {boolean} additional.isAccountPage Account flow identifier
 * @param {string} additional.redirectUrl Account show URL
 */
function PayPalAPMAModel(
    alertHandlerModel,
    btClientInstancePromise,
    payPalButtonSelector,
    payPalConfigurations,
    {
        addPayPalAccountUrl,
        addressObject,
        isAccountPage,
        redirectUrl
    }
) {
    PayPalBaseModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $loaderContainer);

    this.addPayPalAccountUrl = addPayPalAccountUrl + window.braintreeConstants.APMA_QUERY_PARAMETER_TRUE;
    this.addressObject = addressObject;
    this.isAccountPage = isAccountPage;
    this.redirectUrl = redirectUrl;
}

// ES5 inheritance
PayPalAPMAModel.prototype = Object.create(PayPalBaseModel.prototype);

/**
 * Makes call on saving a PayPal default address to the storefront customer.
 * @returns {Object} Whether a success or failed JSON response body.
 */
PayPalAPMAModel.prototype.savePaypalDefaultAddress = function() {
    const addressObject = this.addressObject;
    const isAccountPage = this.isAccountPage;

    return fetch(window.braintreeUrls.savePaypalDefaultAddressUrl, {
        method: 'POST',
        contentType: 'application/json',
        body: JSON.stringify({ addressObject, isAccountPage })
    });
};

PayPalAPMAModel.prototype.initAddButtonEvent = function() {
    const $apmaButtonPayPal = document.querySelector(this.payPalButtonSelector);
    const addingStage = $apmaButtonPayPal.getAttribute('data-adding-stage');

    if (addingStage === window.braintreeConstants.APMA_STAGE_ADDRESS) {
        $apmaButtonPayPal.classList.add('hide-block');

        document.getElementById('js-apma-button-yes').addEventListener('click', () => {
            saveAddressDetails(this);
        });
    }
};

/**
 * For whole description open "payPalBaseModel".
 * We don't generate and send fraud data from Account. That's why "generateFraudData" isn't used
 * @param {Object} payload Buyer data from PayPal (like address, nonce, etc.)
 */
PayPalAPMAModel.prototype.onApprovePaymentCallback = function(payload) {
    // Calling parrent method to proceed with PayPal payload validation
    PayPalBaseModel.prototype.onApprovePaymentCallback.call(this, payload);

    // Error will be handled on BaseModel level
    if (this.error) {
        return;
    }

    this.loader.show();

    const apmaModelInstance = this;
    const btDetails = payload.details;
    const nonce = payload.nonce;
    const email = payload.details.email;
    const $payPalButton = document.querySelector(this.payPalButtonSelector);
    const paypalAccountFormFields = $payPalButton.getAttribute('data-paypal-account-form-fields');
    // Append Shippig/Billing data to FormData
    const payPalBraintreeAddressData = billingFormHelper.createBillingAddressData(btDetails);
    const paypalAccountFormData = helper.createPaymentFormData(paypalAccountFormFields, {
        nonce: nonce,
        email: email,
        addresses: JSON.stringify(payPalBraintreeAddressData),
        shippingAddress: JSON.stringify(payload.details.shippingAddress)
    });

    this.addPayPalAccountUrl += `&apmaStage=${$payPalButton.getAttribute('data-adding-stage')}`;

    // Apped CSRF token
    const $csrfToken = document.getElementById('csrf_token');

    billingFormHelper.appendCsrfTokenToFormData(paypalAccountFormData, $csrfToken);
    // Save payment instrument from PP side
    payPalAccountModelHelper.savePayPalAccount(this.addPayPalAccountUrl, paypalAccountFormData)
        .then(() => {
            saveAddressDetails(apmaModelInstance);
        })
        .catch(function(errorPayload) {
            const error = errorPayload.responseJSON.error;

            apmaModelInstance.alertHandlerModel.showError(error);
            apmaModelInstance.loader.hide();
        });
};

/**
 * Triggers when buyer clicked on the PayPal button and PayPal modal started loading
 * @returns {Object} Object which will be passed directly into Braintree PayPal SDK as an order data
 */
PayPalAPMAModel.prototype.onOrderCreateCallback = async function() {
    const payPalButtonConfigs = this.payPalConfigurations;
    const orderCreateParams = payPalButtonConfigs.options;

    // Hide custom error on buyer PayPal button click.
    this.alertHandlerModel.hideAlerts();

    return {
        flow: orderCreateParams.flow,
        enableShippingAddress: orderCreateParams.enableShippingAddress,
        displayName: orderCreateParams.displayName,
        billingAgreementDescription: orderCreateParams.billingAgreementDescription
    };
};

module.exports = PayPalAPMAModel;
