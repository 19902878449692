'use strict';

const helper = require('../helper');
const paymentMethodGeneralHelper = require('../paymentMethodGeneralHelper');

const CARD_PLACEHOLDER = '************';
const CLASS_D_NONE = 'd-none';
const CLASS_USED_CREDITCARD_ACCOUNT = '.js-braintree-used-creditcard-account';
const USED_ACCOUNT_HIDE = 'used-creditcard-account-hide';
const ATTRIBUTE_NUMBER = 'data-number';
const ATTRIBUTE_EXPIRATION = 'data-expiration';
const ATTRIBUTE_TYPE = 'data-type';
const ATTRIBUTE_OWNER = 'data-owner';
const ATTRIBUTE_NONCE = 'data-nonce';
const FASTLANE_USER_ROLE = 'fastlaneUserRole';
const ROLE_USER = 'user';
const ROLE_GUEST = 'guest';

/**
 * Get HTML element by ID value
 * @param {string} elementId - Specifies the ID value.
 * @returns {HTMLElement|null} - Returns a reference to the first object with the specified value of the ID attribute.
 */
function getElementById(elementId) {
    return document.getElementById(elementId);
}

/**
 * Get all HTML elements by identifier value
 * @param {string} selector - Specifies the selector identifier value.
 * @returns {HTMLElement|null} - Returns all elements with the specified value.
 */
function selectAll(selector) {
    return document.querySelectorAll(selector);
}

/**
 * Adds class to element
 * @param {querySelector} element - current element
 * @param {string} className - current className to add
 */
function addClass(element, className) {
    element?.classList.add(className);
}

/**
 * Removes class from element
 * @param {querySelector} element - current element
 * @param {string} className - current className to remove
 */
function removeClass(element, className) {
    element?.classList.remove(className);
}

/**
 * Gets Sessions card HTML element
 * @returns {HTMLElement|null} - Returns element
 */
function getSessionCardEl() {
    return getElementById('fastlaneSessionCreditCard');
}

/**
 * Gets fastlane payment fields container HTML element
 * @returns {HTMLElement|null} - Returns element
 */
function getPaymentFieldsContainerEl() {
    return getElementById('fastlane-fields-container');
}

/**
 * Gets card list HTML element
 * @returns {HTMLElement|null} - Returns element
 */
function getCardListEl() {
    return getElementById('braintreeCreditCardList');
}

/**
 * Sets nonce to hidden input from passed value or from data attribute
 * @param {string} cardNonce -  nonce
 */
function setNonce(cardNonce) {
    getElementById('braintreeCreditCardNonce').value = cardNonce || getSessionCardEl()?.getAttribute(ATTRIBUTE_NONCE);
}

/**
 * Creates a valid billing address for Fastlane checkout
 * @returns {Object} - A billing address object
 */
function createBillingAddress() {
    const billingData = helper.getBillingAddressFormValues();

    return {
        firstName: billingData.firstName,
        lastName: billingData.lastName,
        address1: decodeURIComponent(billingData.address1),
        address2: decodeURIComponent(billingData.address2),
        city: decodeURIComponent(billingData.city),
        stateCode: billingData.stateCode,
        postalCode: decodeURIComponent(billingData.postalCode),
        country: decodeURIComponent(billingData.country),
        phone: billingData.phone
    };
}

/**
 * Fills Fastlane billing address input with billing address data
 * @param {Object} billingAddress - A billing address object
 */
function setBillingAddress(billingAddress) {
    getElementById('braintreeCardBillingAddress').value = JSON.stringify(billingAddress);
}

/**
 * Fill in the billing phone number field
 * @param {string} value - Phone number value
 */
function setBillingPhoneNumber(value) {
    const phoneNumberEl = getElementById('phoneNumber');

    if (phoneNumberEl && phoneNumberEl.value !== value) {
        phoneNumberEl.value = value;
    }
}

/**
 * Get phone number from shipping section
 * @returns {string} - shipping phone number
 */
function getShippingPhone() {
    return document.querySelector('.shippingPhoneNumber')?.value || '';
}

/**
 * Get phone number from billing section
 * @returns {string} - billing phone number
 */
function getBillingPhone() {
    return document.getElementById('phoneNumber')?.value || getShippingPhone();
}

/**
 * Gets Shipping Address Form Values
 *
 * @returns {Object} with Shipping Address
 */
function getShippingAddressFormValues() {
    const shippingFormData = Object.fromEntries(new FormData(document.getElementById('dwfrm_shipping')));

    return Array.from(Object.entries(shippingFormData)).reduce(function(accumulator, [key, value]) {
        let elem = key.lastIndexOf('_');

        if (elem < 0) {
            accumulator[key] = value;
        } else {
            elem = key.substring(elem + 1);
            accumulator[elem] = value;
        }

        return accumulator;
    }, {});
}

/**
 * Formats billing address from storefront to appropriate object
 * @returns {Object} - billingAddress formatted object
 */
function formatBillingAddress() {
    const billingData = helper.getBillingAddressFormValues();

    return {
        billingAddress: {
            firstName: billingData.firstName,
            lastName: billingData.lastName,
            streetAddress: decodeURIComponent(billingData.address1),
            locality: decodeURIComponent(billingData.city),
            region: billingData.stateCode,
            postalCode: decodeURIComponent(billingData.postalCode),
            countryCodeAlpha2: decodeURIComponent(billingData.country)
        }
    };
}

/**
 * Formats shipping address from storefront to appropriate object
 * @returns {Object} - shippingAddress formatted object
 */
function formatShippingAddress() {
    const shippingData = getShippingAddressFormValues();

    return {
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        streetAddress: decodeURIComponent(shippingData.address1),
        locality: decodeURIComponent(shippingData.city),
        region: shippingData.stateCode,
        postalCode: decodeURIComponent(shippingData.postalCode),
        countryCodeAlpha2: decodeURIComponent(shippingData.country)
    };
}

/**
 * Sets card number into SFCC card number input
 * @param {string} cardNumberLastDigits - last digits of credit card number
 */
function setCardNumber(cardNumberLastDigits) {
    const creditCardNumberEl = getElementById('cardNumber');

    if (creditCardNumberEl && cardNumberLastDigits) {
        creditCardNumberEl.value = CARD_PLACEHOLDER + cardNumberLastDigits;
    }
}

/**
 * Formats card expiration year and month
 * @param {Object} cardData - card data object
 * @returns {Object} - formatted object with expiration month and year
 */
function formatCardExpiration(cardData) {
    return {
        expirationMonth: cardData.expiry.substr(cardData.expiry.length - 2),
        expirationYear: cardData.expiry.slice(0, 4).substr(2)
    };
}

/**
 * Fills Credit Card form hidden inputs with payload data for Fastlane checkout
 * @param {Object} cardData - Object contains the data of new payment method
 * @param {string} cardNonce - nonce
 */
function fillFormWithPayloadData(cardData, cardNonce) {
    const cardExpirationData = formatCardExpiration(cardData);

    getElementById('braintreeCardType').value = cardData.brand;
    getElementById('braintreeCardMaskNumber').value = CARD_PLACEHOLDER + cardData.lastDigits;
    getElementById('braintreeCardExpirationMonth').value = cardExpirationData.expirationMonth;
    getElementById('braintreeCardExpirationYear').value = cardExpirationData.expirationYear;
    getElementById('braintreeCardHolder').value = cardData.name || '';

    setNonce(cardNonce);
    setCardNumber(cardData.lastDigits);
}

/**
 * Sets card data to appropriate input
 * @param {Object} cardData - card data object
 * @param {string} cardNonce - nonce
 */
function setCardData(cardData, cardNonce) {
    if (!cardData) {
        return;
    }

    const cardExpirationData = formatCardExpiration(cardData);

    setNonce(cardNonce);
    setCardNumber(cardData.lastDigits);

    getElementById('braintreeCardType').value = cardData.brand === 'MASTER_CARD' ? 'MASTERCARD' : cardData.brand;
    getElementById('braintreeCardMaskNumber').value = CARD_PLACEHOLDER + cardData.lastDigits;
    getElementById('braintreeCardExpirationMonth').value = cardExpirationData.expirationMonth;
    getElementById('braintreeCardExpirationYear').value = cardExpirationData.expirationYear;
}

/**
 * Shows card info on payment block
 * Will be refactored after 0.6 -> 0.7
 * @param {Object} cardData - card data object
 */
function showCard(cardData) {
    if (!cardData) {
        return;
    }

    const cardTypeEl = getElementById('fastlane-card-type');
    const brand = cardData.brand || 'Unknown';

    cardTypeEl.textContent = brand;
    cardTypeEl.dataset.cardBrand = brand;
    getElementById('fastlane-last4').textContent = cardData.lastDigits;
}

/**
 * Show address data on shipping block
 * @param {Object} address - address data object
 * @param {HTMLElement|null} fastlaneAddressEl - Fastlane address block, where shipping address from account is shown
 *
 */
function setAddress(address, fastlaneAddressEl) {
    if (!address || !fastlaneAddressEl) {
        return;
    }

    fastlaneAddressEl.querySelector('.fastlane-name').textContent = `${address.firstName} ${address.lastName}`;
    fastlaneAddressEl.querySelector('.fastlane-street').textContent = address.address1;
    fastlaneAddressEl.querySelector('.fastlane-city').textContent = address.city;
    fastlaneAddressEl.querySelector('.fastlane-state').textContent = address.stateCode;
    fastlaneAddressEl.querySelector('.fastlane-postal-code').textContent = address.postalCode;
    fastlaneAddressEl.querySelector('.fastlane-phone-number').textContent = address.phone;
}

/**
 * Splits a full name into a separate first as well as a second name
 * We assume that first name is first part of full name and the rest is last name
 * @param {string} fullName A full name
 * @returns {Object} An object with first and last name
 */
function splitFullName(fullName) {
    const fullNameArray = fullName.split(/\s+/);

    return {
        firstName: fullNameArray.shift(),
        lastName: fullNameArray.join(' ')
    };
}

/**
 * Modifies address data object to match SFRA address object
 * @param {Object} addressData - address data object
 * @param {Object} nameData - name data object
 * @param {string} phoneNumber - phone number
 * @returns {Object} - updated address object
 */
function prepareAddressData(addressData, nameData, phoneNumber) {
    if (!addressData) {
        return {};
    }

    let firstName = addressData.firstName || nameData.firstName;
    let lastName = addressData.lastName || nameData.lastName;

    if ((!firstName || !lastName) && nameData) {
        const customerName = splitFullName(nameData);

        firstName = customerName.firstName;
        lastName = customerName.lastName;
    }

    return {
        firstName: firstName,
        lastName: lastName,
        address1: addressData.streetAddress,
        address2: addressData.extendedAddress || '',
        stateCode: addressData.region,
        country: addressData.countryCodeAlpha2,
        city: addressData.locality,
        postalCode: addressData.postalCode,
        phone: addressData.phoneNumber || phoneNumber
    };
}

/**
 * Updates the address form values with address received from Fastlane side
 * @param {HTMLElement} form - shipping/billing form element
 * @param {Object} addressData - address
 *
 */
function updateAddressFormValues(form, addressData) {
    const inputNames = ['firstName', 'lastName', 'address1', 'address2', 'city', 'postalCode', 'phone'];

    if (!form || !addressData) {
        return;
    }

    inputNames.forEach((fieldName) => {
        form.querySelector(`input[name$=_${fieldName}]`).value = decodeURIComponent(addressData[fieldName]);
    });

    // Process the address select elements (Country, State)
    ['select[name$=_stateCode]', 'input[name$=_stateCode]', 'select[name$=_country]', 'input[name$=_country]']
        .forEach((selector) => {
            const element = form.querySelector(selector);

            if (element) {
                element.value = selector.includes('stateCode') ? addressData.stateCode : addressData.country;
            }
        });
}

/**
 * Handles billing address update with address received from Fastlane card data
 * @param {Object} billingAddress - billing address data
 */
function handleBillingAddressUpdate(billingAddress) {
    if (!billingAddress) {
        return;
    }

    updateAddressFormValues(document.querySelector('form[name=dwfrm_billing'), billingAddress);
    paymentMethodGeneralHelper.disableBillingAddressFunctionality();

    const addressOption = Array.from(getElementById('billingAddressSelector').options)
        .find((option) => !['new', '- Existing Shipments -'].includes(option.value));

    if (addressOption) {
        addressOption.selected = true;
        addressOption.textContent = paymentMethodGeneralHelper.formatBaAsString(billingAddress);
        addressOption.value = window.braintreeConstants.SESSION_CARD;

        const phoneNumber = billingAddress.phone || getShippingPhone() || addressOption.dataset.phone;

        setBillingPhoneNumber(phoneNumber);
    }
}

/**
 * Applies prevent event methods
 * @param {event} event - current event
 */
function applyPreventEventMethods(event) {
    event.preventDefault();
    event.stopPropagation();
}

/**
 * Sets session card data attributes
 * @param {Object} cardDetails - card details data
 * @param {string} cardNonce - nonce
 */
function setSessionCardAttributes(cardDetails, cardNonce) {
    const sessionCreditCard = getSessionCardEl();
    const cardExpirationData = formatCardExpiration(cardDetails);

    sessionCreditCard.setAttribute(ATTRIBUTE_NUMBER, CARD_PLACEHOLDER + cardDetails.lastDigits);
    sessionCreditCard.setAttribute(ATTRIBUTE_EXPIRATION, `${cardExpirationData.expirationMonth}/${cardExpirationData.expirationYear}`);
    sessionCreditCard.setAttribute(ATTRIBUTE_TYPE, cardDetails.brand);
    sessionCreditCard.setAttribute('data-last-four', cardDetails.lastDigits);
    sessionCreditCard.setAttribute(ATTRIBUTE_OWNER, cardDetails.name || '');
    sessionCreditCard.setAttribute(ATTRIBUTE_NONCE, cardNonce);
    sessionCreditCard.setAttribute('data-session-account', true);
    sessionCreditCard.setAttribute('data-save-card', false);
}

/**
 * Shows add new/session card selector block
 */
function showSessionCardBlock() {
    removeClass(document.querySelector(CLASS_USED_CREDITCARD_ACCOUNT), USED_ACCOUNT_HIDE);
    addClass(getPaymentFieldsContainerEl(), CLASS_D_NONE);
}

/**
 * Sets session card data and show session card in select
 */
function setSessionCardData() {
    const sessionCreditCard = getSessionCardEl();

    removeClass(sessionCreditCard, USED_ACCOUNT_HIDE);

    sessionCreditCard.selected = true;
    sessionCreditCard.textContent = `${sessionCreditCard.getAttribute(ATTRIBUTE_TYPE)}
    ${sessionCreditCard.getAttribute(ATTRIBUTE_NUMBER)}
    ${sessionCreditCard.getAttribute(ATTRIBUTE_EXPIRATION)}
    ${sessionCreditCard.getAttribute(ATTRIBUTE_OWNER)}`;
}

/**
 * Hide session card block if it is not hidden
 */
function hideSessionCardBlock() {
    if (!document.querySelector(CLASS_USED_CREDITCARD_ACCOUNT)?.classList.contains(USED_ACCOUNT_HIDE)) {
        addClass(document.querySelector(CLASS_USED_CREDITCARD_ACCOUNT), USED_ACCOUNT_HIDE);
    }
}

/**
 * Update Session account
 */
function removeSessionNonce() {
    const sessionOption = helper.getSessionAccountOption({
        querySelector: '#braintreeCreditCardList',
        id: 'fastlaneSessionCreditCard'
    });

    if (!sessionOption) {
        return;
    }

    hideSessionCardBlock();

    getElementById('newCardAccount').selected = true;

    sessionOption.selected = false;
    sessionOption.textContent = '';
    sessionOption.setAttribute('data-session-account', false);
    sessionOption.setAttribute(ATTRIBUTE_OWNER, false);
    sessionOption.setAttribute(ATTRIBUTE_EXPIRATION, false);
    sessionOption.setAttribute(ATTRIBUTE_NUMBER, false);
    sessionOption.setAttribute(ATTRIBUTE_NONCE, false);
    sessionOption.setAttribute(ATTRIBUTE_TYPE, false);
    sessionOption.setAttribute('data-save-card', false);

    removeClass(sessionOption, USED_ACCOUNT_HIDE);
    removeClass(sessionOption, 'used-creditcard-account');
    removeClass(getPaymentFieldsContainerEl(), CLASS_D_NONE);
}

/**
 * Returns selected option from select
 * @param {HTMLElement|null} selectElement - nonce
 * @returns {HTMLElement|null} - selected option
 */
function getSelectedOption(selectElement) {
    return selectElement?.options[selectElement.selectedIndex];
}

/**
 * Returns whether option from select is selected
 * @param {HTMLElement|null} selectList - current select list element
 * @param {string} value - value to check
 * @returns {boolean} - selected option
 */
function isCurrentValueSelected(selectList, value) {
    return Array.from(selectList?.options).find((option) => {
        return option?.value === value && option.selected;
    });
}

/**
 * Returns whether session card flow is used
 * @returns {boolean} - true/false value
 */
function isSessionCardFlow() {
    return isCurrentValueSelected(getCardListEl(), window.braintreeConstants.SESSION_CARD);
}

/**
 * Returns whether new card flow is used
 * @returns {boolean} - true/false value
 */
function isNewCardFlow() {
    return isCurrentValueSelected(getCardListEl(), window.braintreeConstants.NEW_CARD);
}

/**
 * Returns whether non Fastlane nonce exists
 * @returns {boolean} - true/false value
 */
function nonFastlaneNonceExist() {
    return Array.from(selectAll('.payment-options li')).find(function(tab) {
        const paymentMethodName = tab.getAttribute('data-method-id');

        return paymentMethodName !== window.braintreeConstants.PAYMENT_METHOD_ID_CREDIT_CARD
            && getElementById(`braintree${paymentMethodName}Nonce`)?.value !== '';
    });
}

/**
 * Gets array with all elements with appropriate identifier
 * @returns {Array} - array with elements
 */
function getAllShippingFormEls() {
    return Array.from(selectAll('form[name=dwfrm_shipping]'));
}

/**
 * Shows shipping address form block
 * @param {number} exception - exception element position
 */
function showShippingAddressFormBlock(exception) {
    const allShippingAddressFormBlockEls = Array.from(selectAll('.shipping-address-block'));
    const specificBlocks = exception && allShippingAddressFormBlockEls.splice(exception) || null;

    (specificBlocks || allShippingAddressFormBlockEls).forEach((block) => {
        removeClass(block, CLASS_D_NONE);
    });
}

/**
 * Hides shipping address form block
 */
function hideShippingAddressFormBlock() {
    const shippingAddressBlock = Array.from(selectAll('.shipping-address-block'));

    shippingAddressBlock.forEach((block) => {
        addClass(block, CLASS_D_NONE);
    });
}

/**
 * Shows fastlane address block
 * @param {HTMLAllCollection} addressBlocks - address block selectors list
 */
function showFastlaneAddressBlock(addressBlocks) {
    if (!addressBlocks) {
        return;
    }

    Array.from(addressBlocks).forEach((block) => {
        removeClass(block, CLASS_D_NONE);
    });
}

/**
 * Hides fastlane address block
 * @param {HTMLAllCollection} fastlaneAddressBlocks - fastlane address block selectors list
 * @param {number} exception - exception element position
 */
function hideFastlaneAddressBlock(fastlaneAddressBlocks, exception) {
    if (!fastlaneAddressBlocks) {
        return;
    }

    const addressBlocks = Array.from(fastlaneAddressBlocks);
    const specificBlocks = exception && addressBlocks.splice(exception) || null;

    (specificBlocks || addressBlocks).forEach((block) => {
        addClass(block, CLASS_D_NONE);
    });
}

/**
 * Returns true/false value whether fastlane role is user
 * @returns {boolean} - true/false
 */
function isFastlaneRoleUser() {
    return sessionStorage.getItem(FASTLANE_USER_ROLE) === ROLE_USER;
}

/**
 * Returns true/false value whether fastlane role is guest
 * @returns {boolean} - true/false
 */
function isFastlaneRoleGuest() {
    return sessionStorage.getItem(FASTLANE_USER_ROLE) === ROLE_GUEST;
}

/**
 * Handles address update: updates specific form with prepared address and set address to specific container
 * @param {HTMLElement} form - specific form to update
 * @param {Object} address - address which should be set to form and specific container
 * @param {HTMLElement} addressContainer - specific container where updated address should be set
 */
function handleAddressUpdate(form, address, addressContainer) {
    updateAddressFormValues(form, address);
    setAddress(address, addressContainer);
}

/**
 * Returns first element from passed elements list
 * @param {HTMLAllCollection} elementList - all query selector elements list
 * @returns {HTMLElement|null} - first element
 */
function getFirstElement(elementList) {
    if (elementList.length) {
        return Array.from(elementList)[0];
    }

    return null;
}

/**
 * Returns second element from passed elements list
 * @param {HTMLAllCollection} elementList - all query selector elements list
 * @returns {HTMLElement|null} - second element
 */
function getSecondElement(elementList) {
    if (elementList.length) {
        return Array.from(elementList)[1];
    }

    return null;
}

/**
 * @returns {string} - Risk correlation id
 */
function getRiskCorrelationId() {
    const fastlaneConfig = document.querySelector('[data-fastlane-config]')?.dataset.fastlaneConfig;

    if (fastlaneConfig) {
        const { sessionId } = helper.tryParseJSON(fastlaneConfig);

        return sessionId;
    }

    return '';
}

/**
 * Hides address watermarks if any
 */
function hideAddressWatermark() {
    const watermarkList = document.querySelectorAll('.watermark-container');

    if (watermarkList.length) {
        watermarkList.forEach((watermark) => {
            addClass(watermark, CLASS_D_NONE);
        });
    }
}

/**
 * Clears content from passed element
 * @param {HTMLElement} elem - element
 */
function clearElementContent(elem) {
    if (elem) {
        elem.innerHTML = '';
    }
}

/**
 * Gets card holder name
 * @return {string} - card holder name
 */
function getCardholderName() {
    const shippingAddress = getShippingAddressFormValues();

    return `${shippingAddress.firstName} ${shippingAddress.lastName}`;
}

/**
 * Handles billing address functionality appearance (disable/enable)
 */
function handleFastlaneBillingAddressBehavior() {
    const isFastlanePaymentUiEnabled = window.braintreePreferences.isFastlanePaymentUiEnabled;
    const fastlaneCard = sessionStorage.getItem('fastlaneCard');

    if ((isFastlaneRoleUser() && fastlaneCard === 'true')
        || isFastlanePaymentUiEnabled) {
        paymentMethodGeneralHelper.disableBillingAddressFunctionality();
        paymentMethodGeneralHelper.hideBillingAddressBlock();

        if (isFastlanePaymentUiEnabled && (isFastlaneRoleGuest()
            || (isFastlaneRoleUser() && fastlaneCard === 'false'))) {
            paymentMethodGeneralHelper.showPhoneField();
        }
    }
}

module.exports = {
    createBillingAddress,
    setBillingAddress,
    setBillingPhoneNumber,
    getShippingAddressFormValues,
    formatBillingAddress,
    formatShippingAddress,
    fillFormWithPayloadData,
    setCardNumber,
    setCardData,
    showCard,
    setAddress,
    splitFullName,
    prepareAddressData,
    updateAddressFormValues,
    applyPreventEventMethods,
    addClass,
    removeClass,
    getElementById,
    handleBillingAddressUpdate,
    showSessionCardBlock,
    hideSessionCardBlock,
    removeSessionNonce,
    isSessionCardFlow,
    isNewCardFlow,
    setSessionCardAttributes,
    setSessionCardData,
    setNonce,
    nonFastlaneNonceExist,
    getSelectedOption,
    getAllShippingFormEls,
    showShippingAddressFormBlock,
    hideShippingAddressFormBlock,
    showFastlaneAddressBlock,
    hideFastlaneAddressBlock,
    isFastlaneRoleUser,
    isFastlaneRoleGuest,
    handleAddressUpdate,
    getFirstElement,
    getSecondElement,
    getRiskCorrelationId,
    hideAddressWatermark,
    getShippingPhone,
    getBillingPhone,
    clearElementContent,
    getCardholderName,
    handleFastlaneBillingAddressBehavior
};
